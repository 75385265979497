import React, { Component } from "react";
import PageHelmet from "../../component/common/Helmet";
import ScrollToTop from "react-scroll-up";
import { FiChevronUp,  } from "react-icons/fi";
import { FaArrowRight,  } from "react-icons/fa";
import Footer from "../Footer/Footer";
import "./style.css";
import helper from "../../store/Helper";
import Register from "../Register/Register";
import Login from "../Login/Login";
import HttpService from "../../store/HttpServices";
import functionlist from "../../store/functionlist";
import { Link } from "react-router-dom";
import DuCopyFunctionBox from "../Subpagesfunc/DuCopyFunctionBox";
import Ducopyheader from "./Ducopyheader";
import Ducopymenus from "./Ducopymenus";
import { connect } from "react-redux";

class Ducopyactualhome extends Component {
	constructor(props) {
		super(props);
		this.state = {};
	}

	state = {};

	componentDidMount() {
		helper.movetotop();
	}

	render() {
		return (
			<React.Fragment>
				<PageHelmet pageTitle="Homepage" />

				<div className="container">
					<Ducopyheader />

					<div className="row">
						<div className="col-lg-12">
							<div className="text-left" style={{ padding: `40px 0px` }}>
								<p>
									Don't just think about search and replace. With DuCopy, you
									save time modifying texts and strings online. On top of that,
									you can save the functions for later use by creating an
									account.
									<br />
									We du it, you copy it! So, let’s du it!
								</p>
							</div>
						</div>
					</div>

					<div className="container">
                        <div className="rn-page-title text-left" style={{ display:'flex', alignItems:'center', justifyContent:'center', margin:`0px 0px 2rem 0px`}}>
                            <button
                                onClick={()=>{
                                    window.location = "/playground"
                                }} 
                                className="btn-default" type="button" style={{letterSpacing:'0px', fontWeight:'bold', width:'420px', textTransform:'none', borderRadius:4, cursor:'pointer'}}
                            >
                                    Go to playground &nbsp; &nbsp;  <FaArrowRight /> 
                            </button>
                         </div>
                    </div>

					<div className="row">
						<div className="col-lg-12">
							<div className="section-title mb--30">
								<h3 className="title text-center">Use Cases</h3>
							</div>
						</div>
					</div>

					<Ducopymenus />

					<div className="container">
                        <div className="rn-page-title text-left" style={{ display:'flex', alignItems:'center', justifyContent:'center', margin:`30px 0px 20px`}}>
                            <button
                                onClick={()=>{
                                    window.location = "/playground"
                                }} 
                                className="btn-default" type="button" style={{letterSpacing:'0px', fontWeight:'bold', width:'420px', textTransform:'none', borderRadius:4, cursor:'pointer'}}
                            >
                                    Go to playground &nbsp; &nbsp;  <FaArrowRight /> 
                            </button>
                         </div>
                    </div>

					<div className="row">
						<div className="col-lg-12">
							<div className="text-left" style={{ padding: `50px 0px` }}>
								<h2
									className="title text-center"
									style={{
										fontSize: 30,
										padding: `20px 0px`,
										color: "#226E93",
										marginBottom: 0,
									}}
								>
									More Tools Coming Soon
								</h2>
								<p className="text-center">
									Do you have a special request? &nbsp; 
									<a href="/featurerequest"
										style={{color:"#FD4766", fontWeight:'bold'}}
									>Tell us here</a> and we will build
									it specially for you..
								</p>
							</div>
						</div>
					</div>
					{/** 
					<DuCopyFunctionBox />
					*/}
				</div>

					

				<div className="backto-top">
					<ScrollToTop showUnder={160}>
						<FiChevronUp />
					</ScrollToTop>
				</div>

				<Footer />
			</React.Fragment>
		);
	}
}

const mapStateToProps = (state) => {
	return {
		authstatus: state.gReducer.isAuth,
	};
};

const mapDispatchToProps = (dispatch) => {
	return {};
};

export default connect(mapStateToProps, mapDispatchToProps)(Ducopyactualhome);
