import React, { Component } from "react";
import { Link } from 'react-router-dom';
import helper from "../../store/Helper";
import "./style.css";


class Ducopymenus extends Component{
    componentDidMount(){
        helper.movetotop();
    }
    
    render(){
        return(
            <div className="container">
                
                <div className="row">
                                
                    <div className="col-lg-4 col-md-6 col-sm-6 col-12">
                        <div className="rn-address">
                            <a href="/s/bulk-search-and-replace"> 
                                <div className="inner">
                                    <h4 className="title">Bulk Search and Replace</h4>
                                    <p>Search and replace as many terms as possible at a time.</p>
                                   
                                </div>
                            </a>
                        </div>
                    </div>


                    <div className="col-lg-4 col-md-6 col-sm-6 col-12">
                        <div className="rn-address">
                            <a href="/s/bulk-hyperlinking"> 
                                <div className="inner">
                                    <h4 className="title">Bulk Hyperlinking</h4>
                                    <p>By setting a parameter, simply change anchors to hyperlinks.</p>
                                   
                                </div>
                            </a>
                        </div>
                    </div>


                    <div className="col-lg-4 col-md-6 col-sm-6 col-12">
                        <div className="rn-address">
                            <a href="/s/custom-html-tag-remover"> 
                                <div className="inner">
                                    <h4 className="title">Custom HTML Tag Remover</h4>
                                    <p>Strip all html tags or change some to another with custom tag remover.</p>
                                </div>
                            </a>
                        </div>
                    </div>


                    <div className="col-lg-4 col-md-6 col-sm-6 col-12">
                        <div className="rn-address">
                            <a href="/s/serial-text-transformer"> 
                                <div className="inner">
                                    <h4 className="title">Serial Text Transformer</h4>
                                    <p>With just an input, make variants of texts with different outputs.</p>
                                   
                                </div>
                            </a>
                        </div>
                    </div>

                    <div className="col-lg-4 col-md-6 col-sm-6 col-12">
                        <div className="rn-address">
                            <a href="/s/numbered-paragraphs-styling"> 
                                <div className="inner">
                                    <h4 className="title">Numbered Paragraphs Styling</h4>
                                    <p>Apply html styles to numbered paragraphs.</p>
                                </div>
                            </a>
                        </div>
                    </div>

                    <div className="col-lg-4 col-md-6 col-sm-6 col-12">
                        <div className="rn-address">
                            <a href="/s/number-text-lines"> 
                                <div className="inner">
                                    <h4 className="title">Number Text Lines</h4>
                                    <p>Beautifully add number and style to text lines.</p>
                                </div>
                            </a>
                        </div>
                    </div>

                </div>
        
            </div>
        )

    }

}
export default Ducopymenus;