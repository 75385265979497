import React, { Component } from "react";
import PageHelmet from "../../component/common/Helmet";
import ScrollToTop from "react-scroll-up";
import { FiChevronUp } from "react-icons/fi";
import Header from "../Header/Header";
import Footer from "../Footer/Footer";

import { AiFillCodeSandboxCircle } from "react-icons/ai";
import { FaCode, FaArrowLeft, FaSyncAlt } from "react-icons/fa";
import "./style.css";
import helper from "../../store/Helper";
import { saveAs } from "file-saver";
import copy from "copy-text-to-clipboard";
import { Modal } from "react-bootstrap";
import Register from "../Register/Register";
import Login from "../Login/Login";
import HttpService from "../../store/HttpServices";
import ClipLoader from "react-spinners/ClipLoader";
import { css } from "@emotion/react";
import { confirm } from "react-bootstrap-confirmation";
import functionsearch from "./FunctionSearch";
import FunctionSearch from "./FunctionSearch";
import functionlist from "../../store/functionlist";

import ReactTooltip from "react-tooltip";

import Select from "react-select";
import {
	setuserauth,
	setrules,
	showlogin,
	showregister,
} from "../../store/actions/";
import { connect } from "react-redux";

import AssignmentOutlinedIcon from "@material-ui/icons/AssignmentOutlined";
import DeleteOutlinedIcon from "@material-ui/icons/DeleteOutlined";
import SaveOutlinedIcon from "@material-ui/icons/SaveOutlined";
import FileCopyOutlinedIcon from "@material-ui/icons/FileCopyOutlined";
import "./style.css";

const override = css`
	display: block;
	margin: 0 auto;
	margin-bottom: 30px;
`;

class DuCopyFunctionBox extends Component {
	constructor(props) {
		super(props);
		this.state = {
			txtRuleGenerated: "",
			txtReplaceWith: "",
			txtStartWith: "",
			txtEndWith: "",

			ruleid: "",
			rulename: "",
			inputText: "",
			outputText: "",
			allrulesoptions: [],
		};
	}

	state = {
		modalRegister: false,
		modalfunctionsearch: false,
		isloading: null,
	};

	componentDidMount() {
		helper.movetotop();
	}

	fetchAllSavedRules = () => {
		this.setState({
			isloading: true,
		});

		var dataRequest = {
			action: "fetchAllRules",
			searchword: "",
		};
		const http = new HttpService();
		http
			.postData(dataRequest, "access1")
			.then((response) => {
				this.setState({
					isloading: false,
				});

				if (response.status == "success") {
					this.setState({
						allsavedrules: response.data,
					});
					this.props.setuserauth(true);

					console.log("saved rules", response);

					let allrulesoptions = [];
					let dataresponse = response.data;
					if (dataresponse.length > 0) {
						dataresponse.forEach((item) => {
							let ruledata = item.ruledata;
							ruledata = JSON.parse(ruledata);
							allrulesoptions.push({
								label: item.rulename,
								value: {
									txtRuleGenerated: ruledata.txtRuleGenerated,
									rulename: item.rulename,
									ruleid: item.id,
								},
							});
						});

						this.setState(
							{
								allrulesoptions: allrulesoptions,
							},
							() => {
								localStorage.setItem(
									"allrules",
									JSON.stringify(this.state.allrulesoptions)
								);
								this.props.setrules(this.state.allrulesoptions);
								helper.showSuccess("Successfully saved!");
								helper.movetotop();
							}
						);
					}
				} else {
					this.props.setuserauth(false);
					localStorage.clear();
				}
			})
			.catch((error) => {
				this.setState(
					{
						isloading: false,
					},
					() => {
						this.props.setuserauth(false);
						localStorage.clear();
					}
				);
			});
	};

	saveorUpdateRule = () => {
		if (this.props.isAuth == false) {
			this.props.fnxshowlogin(true);
		} else {
			//proceed with the storage
			if (helper.isValidText(this.state.rulename) == false) {
				helper.showError("Please enter rule name");
				return false;
			}

			if (helper.isValidText(this.state.txtRuleGenerated) == false) {
				helper.showError("Please add some functions");
				return false;
			}

			var ruledata = {
				txtRuleGenerated: this.state.txtRuleGenerated,
			};

			var dataRequest = {
				action:
					helper.isValidText(this.state.ruleid) == false
						? "addNewRule"
						: "editRule",
				ruledata: JSON.stringify(ruledata),
				rulename: this.state.rulename,
				ruleid: this.state.ruleid,
			};

			this.setState({
				isloading: true,
			});

			const http = new HttpService();
			http
				.postData(dataRequest, "access1")
				.then((response) => {
					if (response.status == "success") {
						this.fetchAllSavedRules();
					} else {
						helper.showError("Unable to save rule. An error occured!");
					}
				})
				.catch((error) => {
					this.setState({
						isloading: false,
					});
					helper.showError("An error occured!");
					console.log("e", error);
				});
		}
	};

	deleteArule = () => {
		var dataRequest = {
			action: "deleteARule",
			ruleid: this.state.ruleid,
		};

		this.setState({
			isloading: true,
		});

		const http = new HttpService();
		http
			.postData(dataRequest, "access1")
			.then((response) => {
				this.setState({
					isloading: false,
				});

				if (response.status == "success") {
					helper.showSuccess("Successfully deleted!");
					setTimeout(() => {
						window.location = "/";
					}, 1000);
				} else {
					helper.showError("Unable to delete. An error occured!");
				}
			})
			.catch((error) => {
				this.setState({
					isloading: false,
				});
				helper.showError("An error occured!");
				console.log("e", error);
			});
	};

	txtReplaceAction = () => {
		var inputTextData = this.state.inputText;
		try {
			var allrulesgenerated = this.state.txtRuleGenerated.split("\n");
			allrulesgenerated.forEach((item) => {
				let itemgrabbedarray = item.split("⏭️");
				let codename = itemgrabbedarray[0];
				if (codename == "FR") {
					inputTextData = functionlist.basicfindandreplace(
						inputTextData,
						itemgrabbedarray[1],
						itemgrabbedarray[2]
					);
				} else if (codename == "RTT") {
					inputTextData = functionlist.removetagandtext(
						inputTextData,
						itemgrabbedarray[1]
					);
				} else if (codename == "RTO") {
					inputTextData = functionlist.removetagandpreservetext(
						inputTextData,
						itemgrabbedarray[1]
					);
				} else if (codename == "RTWAT") {
					inputTextData = functionlist.replacetagWithAnotherTag(
						inputTextData,
						itemgrabbedarray[1],
						itemgrabbedarray[2]
					);
				} else if (codename == "PARANUM") {
					inputTextData = functionlist.paragraphandnumbering(
						inputTextData,
						itemgrabbedarray[1],
						itemgrabbedarray[2],
						itemgrabbedarray[3]
					);
				} else if (codename == "HYPESTYL") {
					inputTextData = functionlist.hyperlinkingstyling(
						inputTextData,
						itemgrabbedarray[1],
						itemgrabbedarray[2],
						itemgrabbedarray[3]
					);
				} else if (codename == "HYPE") {
					inputTextData = functionlist.identifierhyperlinking(
						inputTextData,
						itemgrabbedarray[1],
						itemgrabbedarray[2],
						itemgrabbedarray[3],
						itemgrabbedarray[4],
						itemgrabbedarray[5],
						itemgrabbedarray[6],
						itemgrabbedarray[7],
						itemgrabbedarray[8],
						itemgrabbedarray[9],
						itemgrabbedarray[10]
					);
				} else if (codename == "SSAR") {
					inputTextData = functionlist.doASearialSearchAndReplace(
						inputTextData,
						itemgrabbedarray[1],
						itemgrabbedarray[2]
					);
				} else if (codename == "AEONP") {
					inputTextData = functionlist.applyEffectOnNumberedParagraph(
						inputTextData,
						itemgrabbedarray[1]
					);
				}
			});

			this.setState({
				outputText: inputTextData,
			});
		} catch (error) {
			this.setState({
				outputText: inputTextData,
			});
		}
	};

	getTotalFunctions = (text)=>{
		if(helper.isValidText(text)==false){
			return 0
		}
		else{
			let total = 0;
			text = text.split("\n");
			text.forEach((each)=>{
				if(helper.isValidText(each)==true){
					total = total+1
				}
			})
			return total;
		}
	}


	render() {
		return (
			<React.Fragment>
				<FunctionSearch
					modalRegister={this.state.modalfunctionsearch}
					closemodal={() => {
						this.setState({
							modalfunctionsearch: false,
						});
					}}
					saved={(rulegenerated) => {
						let currentrule = this.state.txtRuleGenerated;
						if (currentrule == "") {
							currentrule = rulegenerated;
						} else {
							currentrule = currentrule + "\n" + rulegenerated;
						}

						this.setState(
							{
								txtRuleGenerated: currentrule,
								modalfunctionsearch: false,
							},
							() => {
								this.txtReplaceAction();
							}
						);
					}}
				/>

			

				<div className="rn-contact-page bg_color--1" style={{backgroundColor:'#F1F1F1', padding:`30px 0px`}}>
				{this.props.isAuth == true ? (
					<div className="container">
						<div className="row">
							<div className="col-lg-12">
								<div className="rn-page-title text-left" style={{ marginTop: 10, marginBottom:10 }}>
									{/** 
									<h3 className="title">Saved Rules</h3>
									*/}
									<Select
										placeholder = {"Select from saved functions"}
										options={this.props.allrules}
										onChange={(e) => {
											this.setState(
												{
													txtRuleGenerated: e.value.txtRuleGenerated,
													rulename: e.value.rulename,
													ruleid: e.value.ruleid,
												},
												() => {
													this.txtReplaceAction();
												}
											);
										}}
									/>
								</div>
							</div>
						</div>
					</div>
				) : null}

					<div className="contact-form--1">
						<div className="container">
							<div className="playground-box-container">
								<div className="playground-box-wrapper">
									<div className="wrapper">
										{/* <h2 className="title">Input Text</h2> */}
										<div className="form-wrapper">
											<textarea
												type="text"
												value={this.state.inputText}
												onChange={(e) => {
													this.setState({ inputText: e.target.value }, () => {
														this.txtReplaceAction();
													});
												}}
												placeholder="Type in or paste your input text..."
												style={{ height: 350 }}
											/>
										</div>
										{this.state.inputText ? (
											<div className="delete-icon">
												<button
													onClick={(e) => this.setState({ inputText: "", outputText:"" }, ()=>{
														this.txtReplaceAction();
													})}
												>
													<DeleteOutlinedIcon data-tip data-for="delete-text" />
												</button>
												{/** 
												<ReactTooltip
													id="delete-text"
													place="bottom"
													effect="solid"
												>
													Delete text
												</ReactTooltip>
												*/}
											</div>
										) : (
											<div className="paste-icon">
												<button
													onClick={() =>
														navigator.clipboard
															.readText()
															.then((cliptext) =>
																this.setState({ inputText: cliptext }, ()=>{
																	this.txtReplaceAction();
																})
															)
													}
													data-tip
													data-for="paste-text"
												>
													<AssignmentOutlinedIcon />
												</button>
												{/** 
												<ReactTooltip
													id="paste-text"
													place="bottom"
													effect="solid"
												>
													Paste text
												</ReactTooltip>
												*/}
											</div>
										)}
										<div className="bottom-btns my-input">
											<span>
												<b>{this.getTotalFunctions(this.state.txtRuleGenerated)}</b> function(s) applied
											</span>{" "}
											<h4
												onClick={() => {
													this.setState({
														modalfunctionsearch: true,
													});
												}}
												className="title"
												style={{
													textAlign: "left",
													fontSize: 20,
													fontWeight: "bold",
													cursor: "pointer",
													color: "#FD4766",
													textDecoration: "underline",
													marginBottom: "0",
												}}
											>
												+ Add Function
											</h4>
										</div>
									</div>

									<div className="dermacation"></div>
									<div className="wrapper">
										{/* <h2 className="title">Output Text</h2> */}
										<div className="form-wrapper">
											<textarea
												type="text"
												value={this.state.outputText}
												onChange={(e) => {
													this.setState({ outputText: e.target.value });
												}}
												placeholder="Your output appears here..."
												style={{ height: 350 }}
												readOnly={true}
											/>
											<div className="bottom-btns">
												<button
													onClick={() => {
														copy(this.state.outputText);
														helper.showSuccess("Copied");
													}}
													data-tip
													data-for="copy-text"
												>
													<FileCopyOutlinedIcon />
												</button>
												<ReactTooltip
													id="copy-text"
													place="bottom"
													effect="solid"
												>
													copy text
												</ReactTooltip>
												<button
													onClick={() => {
														if (helper.isValidText(this.state.outputText) == true) {
															var blob = new Blob([this.state.outputText], {
																type: "text/plain;charset=utf-8",
															});
															saveAs(blob, `${helper.getUId()}-output.txt`);
														} else {
															helper.showError("No text to save to file");
														}
													}}
													className="last-btn"
													data-tip
													data-for="save-text"
												>
													<SaveOutlinedIcon />
												</button>
												<ReactTooltip
													id="save-text"
													place="bottom"
													effect="solid"
												>
													Export saved text
												</ReactTooltip>
											</div>
										</div>
									</div>
								</div>
							</div>
						</div>

						<div className="container">
							<div className="row row--35 align-items-start">
								<div className="col-lg-12 order-2 order-lg-1">
									{/**
                                            <h2 className="title" style={{textAlign:'center', marginTop:30, fontSize:35, textDecoration:'underline'}}>Add functions to apply. You can add multiple functions</h2>
                                            */}

									<div className="form-wrapper">
										<div>
											<div className="row">
												<div className="col-lg-3"></div>
												<div className="col-lg-6">
													<textarea
														type="text"
														name="message"
														value={this.state.txtRuleGenerated}
														onChange={(e) => {
															this.setState(
																{ txtRuleGenerated: e.target.value },
																() => {
																	this.txtReplaceAction();
																}
															);
														}}
														style={{ height: 100 }}
													/>
												</div>
												<div className="col-lg-3"></div>

												<div className="col-lg-3"></div>
												<div className="col-lg-6">
													<h4
														className="title"
														style={{ fontSize: 20, fontWeight: "bold" }}
													>
														Save function as
													</h4>

													<input
														type="text"
														name="message"
														value={this.state.rulename}
														onChange={(e) => {
															this.setState({ rulename: e.target.value });
														}}
														onFocus={(e) => {
															//e.target.select()
														}}
													/>

													<button
														onClick={() => {
															this.saveorUpdateRule();
														}}
														className="btn-default"
														type="button"
														style={{
															letterSpacing: "0px",
															fontWeight: "bold",
															width: "100%",
															borderRadius: 4,
															textTransform: "none",
															padding: 10,
														}}
													>
														{this.props.isAuth == false
															? "Login To Save Rule"
															: null}

														{this.props.isAuth == true &&
														helper.isValidText(this.state.ruleid) == false
															? "Save Rule"
															: null}

														{this.props.isAuth == true &&
														helper.isValidText(this.state.ruleid) == true
															? "Update Rule"
															: null}
													</button>
													<br />
													<br />

													{this.props.isAuth == true &&
													helper.isValidText(this.state.ruleid) == true ? (
														<button
															onClick={async () => {
																const result = await confirm(
																	"Are you really sure?"
																);
																if (result == true) {
																	this.deleteArule();
																}
															}}
															className="btn-default"
															style={{
																letterSpacing: "0px",
																fontWeight: "bold",
																width: "100%",
																textTransform: "none",
																backgroundColor: "#292321",
																borderColor: "#292321",
																padding: 10,
																borderRadius: 4,
															}}
														>
															Delete rule
														</button>
													) : null}
												</div>
												<div className="col-lg-3"></div>
												{this.state.isloading == true ? (
													<ClipLoader
														css={override}
														size={60}
														color={"gray"}
														loading={true}
													/>
												) : null}
											</div>
										</div>
									</div>
								</div>
							</div>
						</div>
					</div>
				</div>

				<div className="backto-top">
					<ScrollToTop showUnder={160}>
						<FiChevronUp />
					</ScrollToTop>
				</div>
			</React.Fragment>
		);
	}
}

const mapStateToProps = (state) => {
	return {
		isAuth: state.gReducer.isAuth,
		allrules: state.gReducer.allrules,
		showlogin: state.gReducer.showlogin,
		showregister: state.gReducer.showregister,
	};
};

const mapDispatchToProps = (dispatch) => {
	return {
		setuserauth: (data) => dispatch(setuserauth(data)),
		setrules: (data) => dispatch(setrules(data)),
		fnxshowlogin: (data) => dispatch(showlogin(data)),
		fnxshowregister: (data) => dispatch(showregister(data)),
	};
};

export default connect(mapStateToProps, mapDispatchToProps)(DuCopyFunctionBox);
