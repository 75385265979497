import React, { useContext, useState, useEffect } from "react";
import PageHelmet from "../../component/common/Helmet";
import ScrollToTop from "react-scroll-up";
import { FiChevronUp } from "react-icons/fi";
import Footer from "../Footer/Footer";
import "./style.css";
import Ducopyheader from "../Ducopyhome/Ducopyheader";
import DuCopyFunctionBox from "../Subpagesfunc/DuCopyFunctionBox";
import { useDispatch, useSelector } from "react-redux";
import helper from "../../store/Helper";
import ArrowRightAltSharpIcon from "@material-ui/icons/ArrowRightAltSharp";
import { useHistory } from "react-router-dom";
import ModalLoading from "../ModalLoading";
import HttpService from "../../store/HttpServices";

const Dashboard = () => {
	const [loading, setLoading] = useState(null);
	const [modalIsOpen, setModalIsOpen] = useState(false);
	const [userInputData, setUserInputData] = useState(false);
	const [miniaccordion, setMiniAccordion] = useState({
		changepassword:false,
		deleteaccount:false,
	});

	const isAuth = useSelector((state) => state.gReducer.isAuth);

	const handleChange = (event) => {
		var objectkey = event.target.name;
		var objectvalue = event.target.value;
		var oldData = Object.assign({}, userInputData);
		oldData[objectkey] = objectvalue;
		setUserInputData(oldData);
	};


	const history = useHistory();

	useEffect(() => {
		window.scrollTo({
			top: 0,
			left: 0,
			behavior: "smooth",
		});

		if(isAuth!=true && isAuth!=null){
			window.location = "/";
		}
	}, []);


	return (
		<>
			<PageHelmet pageTitle="Dashboard" />

			<ModalLoading
				closemodal={() => {
					setModalIsOpen(false);
				}}
				modalIsOpen={modalIsOpen}
				disablecancel={true}
			/>

			<div className="container">
				<Ducopyheader />

				<div className="row">
					<div className="col-lg-12">
						<div className="text-left" style={{ padding: `40px 0px` }}>
							<h2
								className="title text-center"
								style={{ fontSize: 40, color: "#226E93", marginBottom: 0 }}
							>
								Profile
							</h2>
						</div>
					</div>
				</div>

				<div className="row profilePageWrapper">
					<div className="col-lg-12">
						<div className="section-title mb--30">
							{/** 
							<h3 className="title" style={{padding:`10px 0px`}}>Profile</h3>
							*/}
							<div>
								<h5 className="title">{helper.getudetails("full_name")}</h5>
								<h5 className="title">{helper.getudetails("email")}</h5>
							</div>

							<div 
								className="logout"
								style={{backgroundColor:"#226E93", color:"#FFF", margin:`20px 0px`}}
								onClick={() => {
									var oldData = Object.assign({}, miniaccordion);
									oldData.changepassword = !oldData.changepassword;
									oldData.deleteaccount = false;
									setMiniAccordion(oldData)
								}}
							>	
								<div className="logoutbtntext">Change Password</div>
								<div 
									className="arrowIcon"
									style={{backgroundColor:"#226E93", color:"#FFF"}}
								>
									<ArrowRightAltSharpIcon />
								</div>
							</div>

							{
								miniaccordion.changepassword ==  true ? 

								<div className="mini-cntainer">
									<div>
										<label>Old Password</label>
										<input
											type="password"
											name="oldpassword"
											placeholder="Enter your old password"
											required=""
											onChange={(event) => handleChange(event)}
											style={{ width: "100%" }}
										/>
									</div>

									<div>
										<label>New Password</label>
										<input
											type="password"
											name="newpassword"
											placeholder="Enter new password"
											required=""
											onChange={(event) => handleChange(event)}
											style={{ width: "100%" }}
										/>
									</div>

									<div>
										<label>Re-type Password</label>
										<input
											type="password"
											name="retypenewpassword"
											placeholder="Retype new password"
											required=""
											onChange={(event) => handleChange(event)}
											style={{ width: "100%" }}
										/>
									</div>

									<button
										onClick={()=>{
											if (helper.isValidText(userInputData.oldpassword) == false) {
												helper.showError("Please enter your old password");
												return false;
											} else if (helper.isValidText(userInputData.newpassword) == false) {
												helper.showError("Please enter your new password");
												return false;
											}
											else if (userInputData.newpassword != userInputData.retypenewpassword ) {
												helper.showError("Password does not match");
												return false;
											}
											else{
												setLoading(true);
												setModalIsOpen(true);
												var dataRequest = {
													email: helper.getudetails("email"),
													oldpassword: userInputData.oldpassword,
													newpassword: userInputData.newpassword,
												};

												const http = new HttpService();
												http.postData(dataRequest, "changepassword")
												.then((response) => {
													setLoading(false);
													setModalIsOpen(false);
													console.log("rr", response);
													if (response.status == "success") {
														
														setTimeout(()=>{
															localStorage.clear();
															window.location.reload();
														},2000);
														helper.showSuccess(response.message)
														
														
													} else {
														helper.showError(response.message);
													}
												})
												.catch((error) => {
													console.log("err", error)
													setLoading(false);
													setModalIsOpen(false);
													helper.showError("An error occured!");
												});
											}
										}} 
										className="btn-default" type="button" style={{letterSpacing:'0px', fontWeight:'bold', width:'100%', textTransform:'none', borderRadius:4, cursor:'pointer', padding:`10px 0px`, marginTop:10, backgroundColor:"#226E93", borderColor:"#226E93"}}
									>
											Update Password 
									</button>
								</div>
								
								: null
							}
							


							<div 
								className="logout"
								style={{backgroundColor:"#c02634", color:"#FFF", margin:`40px 0px`}}
								onClick={() => {
									var oldData = Object.assign({}, miniaccordion);
									oldData.deleteaccount = !oldData.deleteaccount;
									oldData.changepassword = false;
									setMiniAccordion(oldData)
								}}
							>	
								<div className="logoutbtntext">Delete Account</div>
								<div 
									className="arrowIcon"
									style={{backgroundColor:"#c02634", color:"#FFF"}}
								>
									<ArrowRightAltSharpIcon />
								</div>
							</div>

							

							{
								miniaccordion.deleteaccount ==  true ? 
								
								<div className="mini-cntainer">
										<div>
											<label>Enter your password to delete account</label>
											<input
												type="password"
												name="password"
												placeholder="Enter your password"
												required=""
												onChange={(event) => handleChange(event)}
												style={{ width: "100%" }}
											/>
										</div>

										<button
											onClick={()=>{
												if (helper.isValidText(userInputData.password) == false) {
													helper.showError("Please enter password");
													return false;
												}
												else{
													setLoading(true);
													setModalIsOpen(true);
													var dataRequest = {
														email: helper.getudetails("email"),
														password: userInputData.password,
														action: "deleteaccount"
													};
													
													const http = new HttpService();
													http.postData(dataRequest, "access1")
													.then((response) => {
														setLoading(false);
														setModalIsOpen(false);
														console.log("rr", response);
														if (response.status == "success") {
															
															setTimeout(()=>{
																localStorage.clear();
																window.location.reload();
															},2000);
															helper.showSuccess(response.message)
															
															
														} else {
															helper.showError(response.message);
														}
													})
													.catch((error) => {
														console.log("err", error)
														setLoading(false);
														setModalIsOpen(false);
														helper.showError("An error occured!");
													});
												}
											}} 
											className="btn-default" type="button" style={{letterSpacing:'0px', fontWeight:'bold', width:'100%', textTransform:'none', borderRadius:4, cursor:'pointer', padding:`10px 0px`, marginTop:10, backgroundColor:"#C02634", borderColor:"#C02634", color:"#FFFFF"}}
										>
												Delete Account
										</button>
									</div>
											
								: null

							}



						</div>
					</div>
				</div>
			</div>
			{/**
			<DuCopyFunctionBox />
			 */}
			<div className="backto-top">
				<ScrollToTop showUnder={160}>
					<FiChevronUp />
				</ScrollToTop>
			</div>
			<Footer />
		</>
	);
};

export default Dashboard;
