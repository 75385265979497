import React, { Component } from "react";
import { Link } from "react-router-dom";
import "./style.css";
import { AiOutlineLogin, AiOutlineLogout } from "react-icons/ai";
import helper from "../../store/Helper";
import HttpService from "../../store/HttpServices";
import PageHelmet from "../../component/common/Helmet";
import Register from "../Register/Register";
import Login from "../Login/Login";
import {
	setuserauth,
	setrules,
	showlogin,
	showregister,
	showforgotpassword
} from "../../store/actions/";
import { connect } from "react-redux";
import SidebarExampleSidebar from "../Sidebar";
import MySidebarMenu from "../Sidebar";
import ForgotPassword from "../ForgotPassword/ForgotPassword";

class Ducopyheader extends Component {
	constructor(props) {
		super(props);
		this.state = {};
	}

	state = {
		isloading: null,
	};

	componentDidMount() {
		helper.movetotop();
		this.checkauthValidity();
	}

	checkauthValidity = () => {
		let authId = localStorage.getItem("authkey");
		console.log(helper);
		if (helper.isValidText(authId) == false) {
			this.props.setuserauth(false);
		} else {
			//Some validity check
			this.setState({
				isloading: true,
			});

			var dataRequest = {
				action: "isvalidauth",
			};
			const http = new HttpService();
			http
				.postData(dataRequest, "access1")
				.then((response) => {
					this.setState({
						isloading: false,
					});
					console.log("rr", response);
					if (response.status == "success") {
						this.props.setuserauth(true);
					} else {
						this.props.setuserauth(false);
						localStorage.clear();
					}
				})
				.catch((error) => {
					this.props.setuserauth(false);
					localStorage.clear();
				});
		}
	};

	render() {
		return (
			<React.Fragment>
				<PageHelmet pageTitle="Homepage" />
				<MySidebarMenu />
				<Register
					modalRegister={this.props.showregister}
					closeModalRegister={() => {
						this.props.fnxshowregister(false);
					}}
					closeRegisterOpenLogin={() => {
						this.props.fnxshowregister(false);
						this.props.fnxshowlogin(true);
					}}
					registerdone={() => {
						this.props.fnxshowregister(false);
					}}
				/>
				<Login
					modalLogin={this.props.showlogin}
					modalForgotPassword = {this.props.showforgotpassword}
					closeModalLogin={() => {
						this.props.fnxshowlogin(false);
					}}
					closeLoginOpenRegister={() => {
						this.props.fnxshowlogin(false);
						this.props.fnxshowregister(true);
					}}
					closeLoginOpenForgotPassword = {()=>{
						this.props.fnxshowlogin(false);
						this.props.fnxshowforgotpassword(true);
					}}
					logindone={() => {
						this.props.fnxshowlogin(false);
					}}
				/>
				<ForgotPassword
					closeLoginOpenRegister={() => {
						this.props.fnxshowlogin(false);
						this.props.fnxshowregister(true);
					}}
					closeLoginOpenForgotPassword = {()=>{
						this.props.fnxshowlogin(false);
						this.props.fnxshowforgotpassword(true);
					}}
					logindone={() => {
						this.props.fnxshowlogin(false);
					}}
				/>
				<div className="row">
					<div className="col-lg-12">
						<div
							className="text-left"
							style={{
								padding: `40px 0px 10px`,
								borderBottom: `2px solid rgba(220,220,220, 0.6)`,
								display: "flex",
								flexDirection: "row",
								justifyContent: "space-between",
								alignItems: "center",
							}}
						>
							
								<div>
									 
									<h2 className="title headerText"><a href="/">DUCOPY</a></h2>
									<p>Your ultimate string and text transformer - made for bloggers!</p>

									{this.props.isAuth == true ? (
										<h2
											className="title"
											style={{
												fontSize: 20,
												color: "#226E93",
												marginBottom: 10,
											}}
										>
											Welcome, {helper.getudetails("full_name")}!
										</h2>
									) : null}
								</div>
							
						
						{/**
							<button
								onClick={() => {
									if (this.props.isAuth == true) {
										setTimeout(()=>{
											this.props.setuserauth(false);
											localStorage.clear();
											window.location = "/";
										},2000);
										helper.showSuccess("Successfully logged out!");
									} else {
										this.props.fnxshowlogin(true);
									}
								}}
								className="btn-default"
								type="button"
								style={{
									letterSpacing: "0px",
									fontWeight: "bold",
									width: "100px",
									textTransform: "none",
									borderRadius: 4,
									padding: `3px 2px`,
								}}
							>
								{this.props.isAuth == true ? (
									<div>
										<AiOutlineLogout /> Logout
									</div>
								) : null}

								{this.props.isAuth == false ? (
									<div>
										<AiOutlineLogin /> Login
									</div>
								) : null}
							</button>
						*/}
						
						</div>
					</div>
				</div>
			</React.Fragment>
		);
	}
}

const mapStateToProps = (state) => {
	return {
		isAuth: state.gReducer.isAuth,
		showlogin: state.gReducer.showlogin,
		showregister: state.gReducer.showregister,
		showforgotpassword: state.gReducer.showforgotpassword
	};
};

const mapDispatchToProps = (dispatch) => {
	return {
		setuserauth: (data) => dispatch(setuserauth(data)),
		setrules: (data) => dispatch(setrules(data)),
		fnxshowlogin: (data) => dispatch(showlogin(data)),
		fnxshowregister: (data) => dispatch(showregister(data)),
		fnxshowforgotpassword: (data) => dispatch(showforgotpassword(data)),
	};
};

export default connect(mapStateToProps, mapDispatchToProps)(Ducopyheader);
