import React, { Component } from "react";
import { Link } from 'react-router-dom';
import { FiX , FiMenu } from "react-icons/fi";
import {Modal, Button, Form} from "react-bootstrap";
import { FaSpinner } from 'react-icons/fa';
import helper from "../../store/Helper";
import ModalLoading from "../ModalLoading";
import HttpService from "../../store/HttpServices";
import { connect } from "react-redux";
import {setuserauth, setrules} from "../../store/actions/"

class Login extends Component{

    state = {
        registerData: {},
        isloading:null,
        
        isloading:null,
        allrulesoptions:[],
    }



    handleChange = (event) => {
        var objectkey = event.target.name;
        var objectvalue = event.target.value;
        var oldData = Object.assign({}, this.state.registerData);
        oldData[objectkey] = objectvalue;
        this.setState({
            registerData:oldData
        })
    };


    fetchAllSavedRules = () => {
        this.setState({
            isloading:true
        })

        var dataRequest = {
            action: "fetchAllRules",
            searchword:"",
        };
        const http = new HttpService();
        http
            .postData(dataRequest, "access1")
            .then((response) => {
               
                if (response.status == "success") {
                    this.setState({
                        allsavedrules: response.data
                    })
                    this.props.setuserauth(true);

                    console.log("saved rules", response);
                    
                    let allrulesoptions = [];
                    let dataresponse = response.data;
                    if(dataresponse.length > 0){
                        dataresponse.forEach((item)=>{
                            let ruledata = item.ruledata;
                            ruledata = JSON.parse(ruledata);
                            allrulesoptions.push({
                                label:item.rulename,
                                value:{
                                    txtRuleGenerated: ruledata.txtRuleGenerated,
                                    rulename: item.rulename,
                                    ruleid: item.id
                                }
                            })
                        })

                        this.setState({
                            allrulesoptions:allrulesoptions,
                            isloading:false
                        }, ()=>{
                            helper.showSuccess("Successfully logged In");
                            localStorage.setItem("allrules", JSON.stringify(this.state.allrulesoptions))

                            this.props.setrules(this.state.allrulesoptions);
                            this.props.setuserauth(true);

                            this.props.closeModalLogin();
                            this.props.logindone(this.state.allrulesoptions);
                        })
                        
                    }
                    
                } else {  
                    this.props.setuserauth(false);
                    localStorage.clear();
                }
            })
            .catch((error) => {
                this.setState({
                    isloading:false,
                })
                this.props.setuserauth(false);
                localStorage.clear();
            });
    }




    render(){
        return(    
                 <Modal
                    show={this.props.modalLogin}
                    backdrop="static"
                    keyboard={false}
                    centered
                >
                    <div style={{pointerEvents: this.state.isloading == true ? 'none' : 'auto'}}> 
                        <Modal.Header 
                            //closeButton
                        >
                            <Modal.Title>Login</Modal.Title>
                        </Modal.Header>

                        <Modal.Body>
                            <Form>
                               

                                <Form.Group controlId="formBasicEmail">
                                    <Form.Label>Email address</Form.Label>
                                    <Form.Control 
                                        type="email" 
                                        name="email" 
                                        placeholder="Enter email" 
                                        onChange={(event) => this.handleChange(event)} 
                                    />
                                </Form.Group>

                                <Form.Group controlId="formBasicPassword">
                                    <Form.Label>Password</Form.Label>
                                    <Form.Control 
                                        type="password" 
                                        name="password" 
                                        placeholder="Password" 
                                        onChange={(event) => this.handleChange(event)} 
                                    />
                                </Form.Group>

                            </Form>
                        </Modal.Body>

                        <Modal.Footer>
                        <div className="loginCFooter">
                            <div>
                                <p
                                    onClick={()=>{
                                        this.props.closeLoginOpenForgotPassword();
                                    }}
                                >Forgot password?</p>

                            <p
                                onClick={()=>{
                                    this.props.closeLoginOpenRegister();
                                }}
                            >Don't have an account?</p>
                            </div>
                          
                            {
                                this.state.isloading == true ?
                                <FaSpinner icon="spinner" className="spinner" />
                                : null
                            }
                            
                            <div style={{flex:1, display:'flex', flexDirection:'row', justifyContent:'flex-end'}}> 
                                <Button variant="secondary" style={{marginRight:10}} onClick={()=>{
                                    this.props.closeModalLogin();
                                }}>Close</Button>
                                <Button
                                    onClick = {()=>{
                                        if(helper.isValidEmail(this.state.registerData.email)==false){
                                            helper.showError("Enter a valid email address");
                                            return;
                                        }
                                        else if(helper.isValidText(this.state.registerData.password)==false){
                                            helper.showError("Enter password");
                                            return;
                                        }
                                        
                                        else{
                                            
                                            this.setState({
                                                isloading:true
                                            })
                                            
                                            var dataRequest = {
                                                email: this.state.registerData.email,
                                                password: this.state.registerData.password,
                                                uapp: "AU"
                                            };
                                    
                                            const http = new HttpService();
                                            http
                                                .postData(dataRequest, "login")
                                                .then((response) => {
                                                   
                                                    console.log("rr", response);
                                                    if (response.status == "success") {
                                                        //logs in..
                                                        localStorage.setItem("authkey", response.token);
                                                        localStorage.setItem("user", JSON.stringify(response.user));
                                                        this.fetchAllSavedRules();

                                                    } else {
                                                        if (response.errors != undefined) {
                                                            var errorList = [];
                                                            for (var key in response.errors) {
                                                                if (response.errors.hasOwnProperty(key)) {
                                                                    var value = response.errors[key][0]
                                                                    errorList.push(value);
                                                                }
                                                            } 
                                                            helper.showError(errorList.join(","));
                                                        } else {
                                                            helper.showError(response.message);
                                                        }
                                                    }
                                                })
                                                .catch((error) => {
                                                    this.setState({
                                                        isloading:false
                                                    })
                                                    helper.showError("An error occured!")
                                                });
                                        }
                                    }} 
                                    variant="success"
                                >Login</Button>
                            </div>
                        </div>   
                    </Modal.Footer>
                    </div>
                
                </Modal>


        )

    }

}

const mapStateToProps = (state) => {
    return {
        authstatus: state.gReducer.isAuth,
    };
};

const mapDispatchToProps = (dispatch) => {
    return {
        setuserauth: (data) => dispatch(setuserauth(data)), 
        setrules: (data) => dispatch(setrules(data)), 
    };
};

export default connect(mapStateToProps, mapDispatchToProps)(Login);