import moment from "moment";
import { ToastContainer, toast } from 'react-toastify';
import 'react-toastify/dist/ReactToastify.css';

class Helper {

    isValidEmail = (text) => {
        var re = /^[^\s@]+@[^\s@]+\.[^\s@]+$/;
        var valid = true;
        if (re.test(text) == false) {
            valid = false;
        }
        return valid;
    }
    

    isValidText = (text) => {   
        if(text == null || text == undefined || text ==""){
            return false;
        }
        text = text.toString();
        if (text.replace(/\s/g, "").length == 0) {
            return false;
        }
        return true;
    }

    isValidPhone = (text) => {
        if(text == null || text == undefined || text ==""){
            return false;
        }
        text = text.toString();
        if (text.replace(/\s/g, "").length == 0) {
            return false;
        }
        if (isNaN(Number(text) - 0)){
            return false;
        }
        if(text.length != 10){
            return false;
        }
        return true;
    }

    isValidNumber = (text) => {
        if(text == null || text == undefined || text ==""){
            return false;
        }
        if (text.replace(/\s/g, "").length == 0) {
            return false;
        }
        if (isNaN(Number(text) - 0)){
            return false;
        }
        return true;
    }

    cgZero = (text) => {
        if(text == null || text == undefined || text ==""){
            return false;
        }
        var txtR = text.replace(",","");
        if(Number(txtR) > 0){
            return true;
        }
        else{
            return false;
        }
    }

    firstNameSentenceCase = (str) => {
        if(this.isValidText(str)==true){
            var firstletter = str.split(' ');
            firstletter = firstletter[0];
            return firstletter.split(' ')
            .map(w => w[0].toUpperCase() + w.substr(1).toLowerCase())
            .join(' ');
        }
        else{
            return "";
        }
       
    }

    ToSentenceCase = (str) => {
        return str.replace(
            /\w\S*/g,
            function(txt) {
              return txt.charAt(0).toUpperCase() + txt.substr(1).toLowerCase();
            }
        );
    }


    isfuturedate = (passeddate) => {
        //todays date
        var startdate = moment(moment(), "YYYY-MM-DD");
        //date passed
        var enddate = moment(passeddate,"YYYY-MM-DD");

        if(moment(enddate).isAfter(startdate)){
            return true;
        }
        else{
            return false;
        }
    }


    isAfter = (firstdate, seconddate) => {
        var firstdate = moment(firstdate,"YYYY-MM-DD");
        var seconddate = moment(seconddate,"YYYY-MM-DD");

        if(moment(firstdate).isAfter(seconddate)){
            return true;
        }
        else{
            return false;
        }
        
    }


    IsJsonString = (str) => {
        if (str == null || str == "" || str == undefined) {
            return false;
        }
        try {
            JSON.parse(str);
        } catch (e) {
            return false;
        }
        return true;
    };



    getUId = () =>{
        return Date.now() + (Math.random() * 100000).toFixed();
    }



    getGreetings = () => {
        var welcome = "";  
        var date = new Date();  
        var hour = date.getHours();  
        var minute = date.getMinutes();  
        var second = date.getSeconds();  
        if (minute < 10) {  
            minute = "0" + minute;  
        }  
        if (second < 10) {  
            second = "0" + second;  
        }  
        if (hour < 12) {  
            welcome = "Good morning!";  
        } else if (hour < 17) {  
            welcome = "Good afternoon!";  
        } else {  
            welcome = "Good evening!";  
        }  
        return welcome;
    }



    showError = (content) => {
        toast.error(content, {
            position: "bottom-center",
            autoClose: 3000,
            hideProgressBar: false,
            closeOnClick: true,
            pauseOnHover: false,
            draggable: true,
            progress: undefined,
        });
    }

    showSuccess = (content) => {
       
        toast.success(content, {
            position: "bottom-center",
            autoClose: 3000,
            hideProgressBar: false,
            closeOnClick: true,
            pauseOnHover: false,
            draggable: true,
            progress: undefined,
        });
    }
    
    returnMonthInNumber = (monthName) => {
        let monthdigit = "";
        if (monthName == "January") {
            monthdigit = "01";
        } else if (monthName == "February") {
            monthdigit = "02";
        } else if (monthName == "March") {
            monthdigit = "03";
        } else if (monthName == "April") {
            monthdigit = "04";
        } else if (monthName == "May") {
            monthdigit = "05";
        } else if (monthName == "June") {
            monthdigit = "06";
        } else if (monthName == "July") {
            monthdigit = "07";
        } else if (monthName == "August") {
            monthdigit = "08";
        } else if (monthName == "September") {
            monthdigit = "09";
        } else if (monthName == "October") {
            monthdigit = "10";
        } else if (monthName == "November") {
            monthdigit = "11";
        } else if (monthName == "December") {
            monthdigit = "12";
        }
        return monthdigit;
    };

    toLocalDate = (date) =>{
        var local = new Date(date);
        local.setMinutes(date.getMinutes() - date.getTimezoneOffset());
        return local.toJSON().slice(0, 10);
    }

    toFriendlyDate = (date) => {
        return moment(date).format("dddd, MMMM Do YYYY");
    }
    
    toYYYYMMDD = (date) => {
        return moment(date).format("YYYY-MM-DD");
    }

    nowOrFutureValid = (mdate) =>{
        let pdate = moment(mdate, "YYYY-MM-DD");
        let dateValidate = moment().diff(pdate, 'days');

        let valuetoreturn = false;
        console.log(dateValidate);
        if(dateValidate > 0){
            valuetoreturn = false; //past
        }
        else{
            valuetoreturn = true; //now or future date
        }
        return valuetoreturn;
    }


    convertTo24Hrs = (s) => {
        let AMPM = s.slice(-2);
        let timeArr = s.slice(0, -2).split(":");
        if (AMPM === "AM" && timeArr[0] === "12") {
            // catching edge-case of 12AM
            timeArr[0] = "00";
        } else if (AMPM === "PM") {
            // everything with PM can just be mod'd and added with 12 - the max will be 23
            timeArr[0] = (timeArr[0] % 12) + 12
        }  
        return timeArr.join(":");
    }


    timeValidity = (alltime) => {
        
        let validTime = [];
        let currenttime = moment().format('HH:mm:ss');
        currenttime = Number(currenttime.split(":")[0]);
        alltime.forEach((item)=>{
            let eachtimeSplit = item.split("-");
            let eachtimein24Hrs1 = this.convertTo24Hrs(eachtimeSplit[0]);
            let eachtimein24Hrs2 = this.convertTo24Hrs(eachtimeSplit[1]);

            eachtimein24Hrs1 = Number(eachtimein24Hrs1.split(":")[0]);
            eachtimein24Hrs2 = Number(eachtimein24Hrs2.split(":")[0]);

            /*
            console.log("current time", currenttime);
            console.log("eachtimein24Hrs1", eachtimein24Hrs1);
            console.log("eachtimein24Hrs2", eachtimein24Hrs2);
            */


            if(currenttime < eachtimein24Hrs1 && currenttime < eachtimein24Hrs2){
                validTime.push(item);
                //console.log(item + " pushed")
            }
            else{
                //console.log(item + "not pushed")
            }
        })

        return validTime;
       
    }

//pk_test_4TJNAvEsZoybuefUhvvUf87BVnMe5NRCZCeWNwV2
//pk_live_gonJKmbySomWhnJodsg3rS75vKb9qpV7ad1Za6G5
    makePayment = (currency, amount, name, email)=> {
        return new Promise((resolve) => {
            try {
                window.Korapay.initialize({
                    key:"pk_live_gonJKmbySomWhnJodsg3rS75vKb9qpV7ad1Za6G5", 
                    amount: amount, 
                    currency: currency,
                    customer: {
                        name: name,
                        email: email,
                    },
                    onClose: function () {
                        //Handle when modal is closed
                    },
                    onSuccess: function (data) {
                        let response = {
                            status:"S",
                            data:data
                        }
                        resolve(response);
                    },
                    onFailed: function (data) {
                        let response = {
                            status:"F",
                            data:data
                        }
                        resolve(response);
                    },
                    onClose : function (data) {
                        let response = {
                            status:"C",
                            data:data
                        }
                        resolve(response);
                    }
                });
            } catch (error) {
                let response = {
                    status:"ERR",
                    data:error
                }
                resolve(response);
            }
           
        });
       
    }
   

    debounce = (func, wait, immediate) => {
        var timeout;
        return function () {
            var context = this, args = arguments;
            var later = function () {
                timeout = null;
                if (!immediate) func.apply(context, args);
            };
            var callNow = immediate && !timeout;
            clearTimeout(timeout);
            timeout = setTimeout(later, wait);
            if (callNow) func.apply(context, args);
        };
    };

    movetotop = ()=>{
        window.scrollTo({
            top: 0,
            left: 0,
            behavior: "smooth",
        });
    }

    getIndicesOf = (searchStr, str, caseSensitive) =>{
        var searchStrLen = searchStr.length;
        if (searchStrLen == 0) {
            return [];
        }
        var startIndex = 0, index, indices = [];
        if (!caseSensitive) {
            str = str.toLowerCase();
            searchStr = searchStr.toLowerCase();
        }
        while ((index = str.indexOf(searchStr, startIndex)) > -1) {
            indices.push(index);
            startIndex = index + searchStrLen;
        }
        return indices;
    }
    
    
    isEmptyTag = (val)=>{
        val = val.toLowerCase();
        let emptyTags = [
            "area",
            "base",
            "br",
            "col",
            "embed",
            "hr",
            "img",
            "input",
            "keygen",
            "link",
            "meta",
            "param",
            "source",
            "track",
            "wbr",
        ];
    
        let indexval = emptyTags.findIndex(
            (obj) => obj == val
        );
    
        if(indexval==-1){
            return false;
        }
        else{
            return true;
        }
    }

    getudetails = (key)=>{
        let user = localStorage.getItem("user");
        if(user!=null && user!=""){
            user = JSON.parse(user);
            return user[key];
        }
        else{
            return "";
        }

    }

    isBol = (val)=>{
        if (String(val).toLowerCase() == "true"){
            return true;
        }
        else{
            return false;
        }
    }

    determinerelval = (istarget, isnofollow, isnosponsored) =>{
        if(istarget==false && isnofollow==false && isnosponsored==false){
            return '';
        }
        else if(isnofollow==true && isnosponsored==true){
            return 'rel="nofollow noopener sponsored"';
        }
        else if(isnofollow==true && isnosponsored==false){
            return 'rel="nofollow noopener"';
        }
        else if(isnofollow==false && isnosponsored==true){
            return 'rel="noopener sponsored"';
        }
        else if(istarget==true && isnofollow==false && isnosponsored==false ){
            return 'rel="noopener"';
        }
    }


    insertatext = (str, index, value)=>{
        return str.substr(0, index) + value + str.substr(index);
    }


    determinerelvalhyperlinkstyling = (text, istarget, isnofollow, isnosponsored) =>{
    
        if(text.toLowerCase().includes('target'.toLowerCase()) && istarget==false){
            text = text.replace(`target="_blank"`, "");
        }
        if(!text.toLowerCase().includes('target'.toLowerCase()) && istarget==true){
            var n=text.indexOf(">");
            text = text.substring(0,n)+` target="_blank" `+text.substring(n);
        }
    
        if(text.toLowerCase().includes('nofollow'.toLowerCase()) && isnofollow==false){
            text = text.replace(`nofollow`, "");
        }
        if(!text.toLowerCase().includes('nofollow'.toLowerCase()) && isnofollow==true){
            if(text.toLowerCase().includes('rel'.toLowerCase())){
                var n=text.indexOf(`rel="`);
                text = this.insertatext(text, n + 5, ` nofollow `);
            }
            else{
                var n=text.indexOf(">");
                text = text.substring(0,n)+` rel="nofollow" `+text.substring(n);
            }
        }
    
        if(text.toLowerCase().includes('sponsored'.toLowerCase()) && isnosponsored==false){
            text = text.replace(`sponsored`, "");
        }
        if(!text.toLowerCase().includes('sponsored'.toLowerCase()) && isnosponsored==true){
            if(text.toLowerCase().includes('rel'.toLowerCase())){
                var n=text.indexOf(`rel="`);
                text = this.insertatext(text, n + 5, ` sponsored `);
            }
            else{
                var n=text.indexOf(">");
                text = text.substring(0,n)+` rel="sponsored" `+text.substring(n);
            }
        }
    
    
        if(text.toLowerCase().includes(`rel=""`.toLowerCase())){
            text = text.replace(`rel=""`, "");
        }
    
        if(text.toLowerCase().includes(`target`.toLowerCase()) || text.toLowerCase().includes(`nofollow`.toLowerCase()) || text.toLowerCase().includes(`sponsored`.toLowerCase())){
            if(!text.toLowerCase().includes(`noopener`.toLowerCase())){
                if(text.toLowerCase().includes('rel'.toLowerCase())){
                    var n=text.indexOf(`rel="`);
                    text = this.insertatext(text, n + 5, ` noopener `);
                }
                else{
                    var n=text.indexOf(">");
                    text = text.substring(0,n)+` rel="noopener" `+text.substring(n);
                }
            }
        }
        text = text.replace(`rel=" noopener`, `rel="noopener`);
        text = text.replace(`nofollow" >`, `nofollow">`);
        return text;
    
    }

    
    trimmingitem = (textpassed, identifier, igidinanchor, ighypinanchor, igundinanchor) => {
        if(igidinanchor==true){
            textpassed = textpassed.replaceAll(identifier,"");
        }
    
        if(ighypinanchor==true){
            textpassed = textpassed.replaceAll("-"," ");
        }
    
        if(igundinanchor==true){
            textpassed = textpassed.replaceAll("_"," ");
        }
    
        return textpassed;
    }


    allurlpages = (key) => {
        let urldata = [
            {
                key : `bulk-search-and-replace`,
                title:`Bulk Search and Replace`,
                infodata:`
                <p>The bulk search and replace was created for easier use. You can search and replace multiple texts at a time.</p><br/>
                <p style="font-size:20px; color:black; font-weight:bold;">How it Works</p>
                <ol class='listinginfo'>
                    <li>Launch the tool from homepage</li>
                    <li>Paste in your input (the copy you want to search text or string from).</li>
                    <li>Click the Add Function button </li>
                    <li>Choose <b>Bulk Search and Replace</b> function from the available options or easily search it.</li>
                    <li>Insert the text or string to be replaced in the box. If you would want to search more than one, separate them with the symbol | </li>
                    <li>Insert the replacement text or string in the box provided. If you would want to replace more than one, separate them with the symbol |  in the same order you have the ones you would want replaced.</li>
                    <li>Copy your output to clipboard or save as file.</li>
                </ol>`
            },
            {
                key : `bulk-hyperlinking`,
                title:`Bulk Hyperlinking`,
                infodata:`
                <p>I have a site where I share Instagram Captions. Each time I write hashtags, I want to simply turn them into clickable links to their respective pages so we created a function for that.</p><br/>
                <p>Even though With the bulk hyperlinking feature, you need to have some anchors you would to hyperlink and then provide the url sample to use.</p>
                <p style="font-size:20px; color:black; font-weight:bold;">How it Works</p>
                <ol class='listinginfo'>
                    <li>Launch the tool from homepage</li>
                    <li>Paste in your input (the copy you want to search text or string from).</li>
                    <li>Click the Add Function button </li>
                    <li>Choose <b>Bulk Hyperlinking</b> function from the available options or easily search it.</li>
                    <li>Insert the identifier ( the identifier is the symbol before the anchors. We recommed # but you are not limited).</li>
                    <li>Insert the url structure to use. Example: <span style="color:#226E93; font-weight:bold;">https://site.com/</span> 
                    <br/>
                    It means an anchor like #contact will turn to
                    <br/>
                    &lt;a href="https://site.com/contact/"&gt;contact&lt;/a&gt;
                    </li>
                    <li>Copy your output to clipboard or save as file and be happy!</p>
                </ol>`
            },
            {
                key : `custom-html-tag-remover`,
                title:`Custom HTML Tag Remover`,
                infodata:`
                <p>Each time I get a guestpost samples from authors, especially those created from Google docs and the likes of it, the styling isn't great. If I have to copy and paste into wordpress editor without some readjustments, I am left with a post with different font style, font sizes and some annoying spans.</p>
                <p>Creating the html tag remover feature was the way forward for me. And even if it was the first reason this feature was created, its use case is limitless.</p>
                <p>So you would want to totally remove or change the html tags in a copy? You are welcome.</p>
                <p>The beauty of this feature is, you can use the three options together for a copy, plus save it for later use (like every feature) so whenever you receive a guest post for instance, just apply the saved functions to it and you have the output you desire.</p>
                <p>There are 3 different options available for the html tag remover</p>
                <ul class='listinginfo'>
                    <li style="font-weight:bold">Remove html tag only</li>
                    This option removes only the html tags: "&lt;p&gt;texts within&lt;/p&gt;" becomes "texts within".

                    <li style="font-weight:bold">Remove html tag and text within</li>
                    This option removes both the html tag and the texts within: "&lt;p&gt;texts within&lt;/p&gt;" becomes empty.

                    <li style="font-weight:bold">Replace html tag with another</li>
                    With this option, you can simply replace the html tag with another. A good use case is changing lists to headings: &lt;li&gt;this is a test&lt;/li&gt; becomes &lt;h2&gt;this is a test&lt;/h2&gt;
                </ul>


                <p style="font-size:20px; color:black; font-weight:bold;">How it Works</p>
                <ol class='listinginfo'>
                    <li>Launch the tool from homepage</li>
                    <li>Paste in your input (the copy you want to search text or string from).</li>
                    <li>Click the Add Function button </li>
                    <li>Choose any of the 3 functions <b>"Remove html tag only, Remove html tag and text and Replace html tag with another"</b> from the available options or easily search it.</li>
                    <li>To remove tag only, simply insert the tags to remove and separate multiple by the symbol, | </li>
                    <li>To remove html tag and text, simply insert the tags to remove and separate multiple by the symbol, |</li>
                    <li>To replace an html tag with another tag, simply insert the tags to remove and separate multiple by the symbol, |</li>
                    <li>Instantly copy your output to clipboard or save as file and be happy!</li>
                </ol>`
            },
            {
                key : `number-text-lines`,
                title:`Number Text Lines`,
                infodata:`
                <p>This basically helps when you need to add paragraph and numbering to some lines of texts.</p><br/>
                <p style="font-size:20px; color:black; font-weight:bold;">How it Works</p>
                <ol class='listinginfo'>
                    <li>Launch the tool from homepage</li>
                    <li>Paste in your input (the copy you want to search text or string from).</li>
                    <li>Click the Add Function button </li>
                    <li>Choose <b>Paragraph and Numbering</b> function from the available options or easily search it.</li>
                    <li>Click Apply</li>
                    <li>Now copy your output to clipboard or save as file and be happy!</li>
                </ol>`
            },
            {
                key : `numbered-paragraphs-styling`,
                title:`Numbered Paragraphs Styling`,
                infodata:`
                <p>You have a post with numbered paragraphs or lines and you would love to change their formating from what they have presently? Say no more!</p>
                <p>The Numbered paragraphs styling feature was perfectly created for this: you want to turn bold to heading, or turn list items to headings? All is possible with this lovely feature.</p><br/>
                <p style="font-size:20px; color:black; font-weight:bold;">How it Works</p>
                <ol class='listinginfo'>
                    <li>Launch the tool from homepage</li>
                    <li>Paste in your input (the copy you want to search text or string from).</li>
                    <li>Click the Add Function button </li>
                    <li>Choose <b>Numbered Paragraphs Styling</b> function from the available options or easily search it.</li>
                    <li>Insert the styling needed.</li>
                    <li>Click Apply</li>
                    <li>Now copy your output to clipboard or save as file and be happy!</li>
                    <b>Note: You can chain the feature with any other feature you love for better output)</b>
                </ol>`
            },
            {
                key : `serial-text-transformer`,
                title:`Serial Text Transformer`,
                infodata:`
                <p>This feature is particularly useful when doing keyword research: you have a few keywords you want to have multiple variations of and you just have to keep typing one by one.</p>
                <p>
                    <b>A good sample input</b>: <br/>
                    how long does a <b>dog</b> live on average?<br/>
                    how to make a <b>dog</b> like you<br/>
                    how to feed a <b>dog</b><br/>
                    how does a <b>dog</b> sleep?<br/>
                    Can a dog <b>swim</b>?<br/>
                </p><br/>

                <p>I want to make variations of the above set of keywords for other animals like <b>cat, sheep, monkey, goat, cow, pig, horse, camel, and donkey.</b></p>

                <p>Here, I only need to submit one input, set the word <b>dog</b> as the identifier, then place in the transformers (other animals, in this case) and the system generates as many outputs as I want with the transformers in them.</p>

                <p style="font-size:20px; color:black; font-weight:bold;">How it Works</p>
                <ol class='listinginfo'>
                    <li>Launch the tool from homepage</li>
                    <li>Paste in your input (the copy you want to search text or string from).</li>
                    <li>Click the Add Function button </li>
                    <li>Choose <b>Serial Text Transformer </b> function from the available options or easily search it.</li>
                    <li>Insert the identifier ( the identifier is the word you want to transform. That will be dog in the sample input above).</li>
                    <li>Paste in transformers and separate them by the symbol |</li>
                    <li>Copy your output to clipboard or save as file and be happy!</li>
                </ol>`
            }
        ];

        let result = {};
        let objectIndex = urldata.findIndex(
            (obj) => obj.key == key
        );
        if (objectIndex != -1) {
            result = urldata[objectIndex];
        }else{
            result = {};
        }
        return result;
    }

    
}
const helper = new Helper();
export default helper;
