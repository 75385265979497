import React, { Component, useState, useEffect } from "react";
import PageHelmet from "../../component/common/Helmet";
import ScrollToTop from "react-scroll-up";
import { FiChevronUp } from "react-icons/fi";
import Footer from "../Footer/Footer";
import "./style.css";
import helper from "../../store/Helper";
import Register from "../Register/Register";
import Login from "../Login/Login";
import HttpService from "../../store/HttpServices";
import Ducopyheader from "../Ducopyhome/Ducopyheader";
import HomeOutlinedIcon from "@material-ui/icons/HomeOutlined";
import PhoneOutlinedIcon from "@material-ui/icons/PhoneOutlined";
import EmailOutlinedIcon from "@material-ui/icons/EmailOutlined";
import Select from 'react-select'
import ModalLoading from "../ModalLoading";



const ContactPage = () => {
	

	const [loading, setLoading] = useState(null);
	const [modalIsOpen, setModalIsOpen] = useState(false);
	const [userInputData, setUserInputData] = useState(false);
	
	const handleChange = (event) => {
		var objectkey = event.target.name;
		var objectvalue = event.target.value;
		var oldData = Object.assign({}, userInputData);
		oldData[objectkey] = objectvalue;
		setUserInputData(oldData);
	};

	return (
		<>
			<ModalLoading
				closemodal={() => {
					setModalIsOpen(false);
				}}
				modalIsOpen={modalIsOpen}
				disablecancel={true}
			/>

			<PageHelmet pageTitle="Contact" />
			<div className="container">
				<Ducopyheader />

				<div className="row">
					<div className="col-lg-12">
						<div className="text-left" style={{ padding: `40px 0px` }}>
							<h2
								className="title text-center"
								style={{ fontSize: 40, color: "#226E93", marginBottom: 0 }}
							>
								Contact Us
							</h2>
						</div>
					</div>
				</div>

				{/* <p className="text-center w-responsive mx-auto mb-5">
					Do you have any questions or features suggestions? Please do not hesitate to contact us
					directly. We are willing to hear from you.
				</p> */}

				<div className="row justify-content-between">
					<div className="col-md-8 mb-md-0 mb-5">
						<iframe
							src="https://form.nativeforms.com/yYTWR1jZmQFaxETaH1Db"
							width="100%"
							height="700"
							frameborder="0"
						>
						</iframe>
					</div>

					{/* <div className="col-md-8 mb-md-0 mb-5">
						<form
							id="contact-form"
							name="contact-form"
							action="mail.php"
							method="POST"
						>
							<div className="row mb-4">
								<div className="col-md-6  mb-4">
									<div className="md-form mb-0">
										<input
											type="text"
											name="yourname"
											placeholder="Enter your name"
											required=""
											onChange={(event) => handleChange(event)}
											style={{ width: "100%", padding:25, borderRadius:4, fontSize:16 }}
										/>
									</div>
								</div>

								<div className="col-md-6">
									<input
										type="email"
										name="youremail"
										placeholder="Enter your email address"
										required=""
										onChange={(event) => handleChange(event)}
										style={{ width: "100%", padding:25, borderRadius:4, fontSize:16}}
									/>
								</div>
							</div>

							<div className="row">
								<div className="col-md-12">
									<div className="md-form mb-4">
										<Select 
											options={[
												{label: 'Feature Suggestion',value:"Feature Suggestion"},
												{label: 'Contact Reason',value:"Contact Reason"},
											]} 
											onChange={(e)=>{
												var oldData = Object.assign({}, userInputData);
												oldData.contactreason = e.value;
												setUserInputData(oldData);
											}}
											placeholder={"Select contact reason"}
											style={{ width: "100%", padding:25, borderRadius:4}}
										/>
									</div>
								</div>
							</div>

							<div className="row">
								<div className="col-md-12">
									<div className="md-form mb-4">
										<textarea
											type="text"
											name="yourmessage"
											placeholder="Enter your message content"
											required=""
											onChange={(event) => handleChange(event)}
											rows="8"
											className="form-control md-textarea"
										></textarea>
									</div>
								</div>
							</div>
						</form>

						<div className="text-center text-md-left">
						<button
							onClick={()=>{
								if (helper.isValidText(userInputData.yourname) == false) {
									helper.showError("Please enter your name");
									return false;
								} else if (helper.isValidEmail(userInputData.youremail) == false) {
									helper.showError("Please enter a valid email address");
									return false;
								}
								else if (helper.isValidText(userInputData.contactreason) == false) {
									helper.showError("Please select contact reason");
									return false;
								}
								else if (helper.isValidText(userInputData.yourmessage) == false) {
									helper.showError("Please enter contact reason");
									return false;
								}
								else{
									setLoading(true);
									setModalIsOpen(true);

									let messagecontent = `
									<b>Sender Details</b><br>
									Name:		${userInputData.yourname},<br/>
									Email:		${userInputData.youremail},<br/>


									<br><br>
									<b>Message Content</b><br>
									Reason:		${userInputData.contactreason},<br/>
									Message:		${userInputData.yourmessage},<br/>
									`;

									var dataRequest = {
										yourname: userInputData.yourname,
										youremail: userInputData.youremail,
										contactreason: userInputData.contactreason,
										yourmessage: messagecontent,
									};

									
									const http = new HttpService();
									http.postData(dataRequest, "contactmessage")
									.then((response) => {
										setLoading(false);
										setModalIsOpen(false);
										
										setTimeout(()=>{
											window.location.reload();
										}, 2000);
										if (response.status == "success") {
											helper.showSuccess(response.message)
										} else {
											helper.showError(response.message);
										}
										

										var oldData = Object.assign({}, userInputData);
										oldData.yourname = "";
										oldData.youremail = "";
										oldData.yourmessage = "";
										oldData.contactreason = "";
										setUserInputData(oldData);

									})
									.catch((error) => {
										console.log("err", error)
										setLoading(false);
										setModalIsOpen(false);
										helper.showError("An error occured!");
									});
								}
							}} 
							className="btn-default" type="button" style={{letterSpacing:'0px', fontWeight:'bold', width:'100%', textTransform:'none', borderRadius:4, cursor:'pointer', padding:`10px 0px`, marginTop:10, backgroundColor:"#226E93", borderColor:"#226E93"}}
						>
							Send Message
						</button>
						</div>
						<div className="status"></div>
					</div> */}

					<div className="col-md-3 text-left">
						<ul className="list-unstyled mb-0">
							<li className="mb-2 d-flex align-items-center">
								<HomeOutlinedIcon className="mr-3" />
								<p>United Kingdom</p>
							</li>

						

							<li className="mb-2 d-flex align-items-center">
								<EmailOutlinedIcon className="mr-3" />
								<p>info@ducopy.com</p>
							</li>
						</ul>
					</div>
				</div>
			</div>
			<br />
			<br />
			<div className="backto-top">
				<ScrollToTop showUnder={160}>
					<FiChevronUp />
				</ScrollToTop>
			</div>
			<Footer />
		</>
	);
};

export default ContactPage;
