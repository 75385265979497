import React, { Component } from "react";
import { Link } from 'react-router-dom';
import { FiX , FiMenu } from "react-icons/fi";
import {Modal, Button, Form} from "react-bootstrap";
import { FaSpinner } from 'react-icons/fa';
import helper from "../../store/Helper";
import ModalLoading from "../ModalLoading";
import HttpService from "../../store/HttpServices";

class Register extends Component{

    state = {
        registerData: {},
        isloading:null
    }

    handleChange = (event) => {
        var objectkey = event.target.name;
        var objectvalue = event.target.value;
        var oldData = Object.assign({}, this.state.registerData);
        oldData[objectkey] = objectvalue;
        this.setState({
            registerData:oldData
        })
    };

    render(){
        return(
        
                 <Modal
                    show={this.props.modalRegister}
                    backdrop="static"
                    keyboard={false}
                    centered
                >
                    <div style={{pointerEvents: this.state.isloading == true ? 'none' : 'auto'}}> 
                        <Modal.Header 
                            //closeButton
                        >
                            <Modal.Title>Create an account</Modal.Title>
                        </Modal.Header>

                        <Modal.Body>
                            <Form>
                                <Form.Group controlId="formBasicEmail">
                                    <Form.Label>Full name</Form.Label>
                                    <Form.Control 
                                        type="text" 
                                        name="full_name" 
                                        placeholder="Enter fullname"
                                        onChange={(event) => this.handleChange(event)} 
                                    />
                                </Form.Group>

                                <Form.Group controlId="formBasicEmail">
                                    <Form.Label>Email address</Form.Label>
                                    <Form.Control 
                                        type="email" 
                                        name="email" 
                                        placeholder="Enter email" 
                                        onChange={(event) => this.handleChange(event)} 
                                    />
                                </Form.Group>

                                <Form.Group controlId="formBasicPassword">
                                    <Form.Label>Password</Form.Label>
                                    <Form.Control 
                                        type="password" 
                                        name="password" 
                                        placeholder="Password" 
                                        onChange={(event) => this.handleChange(event)} 
                                    />
                                </Form.Group>

                                <Form.Group controlId="formBasicPassword">
                                    <Form.Label>Confirm Password</Form.Label>
                                    <Form.Control 
                                        type="password" 
                                        name="confirmpassword" 
                                        placeholder="Confirm Password" 
                                        onChange={(event) => this.handleChange(event)} 
                                    />
                                </Form.Group>
                            </Form>
                        </Modal.Body>

                        <Modal.Footer>
                        <div className="loginCFooter">
                            <p
                                onClick={()=>{
                                    this.props.closeRegisterOpenLogin();
                                }}
                            >Already have an account?</p>
                            {
                                this.state.isloading == true ?
                                <FaSpinner icon="spinner" className="spinner" />
                                : null
                            }
                            
                            <div style={{flex:1, display:'flex', flexDirection:'row', justifyContent:'flex-end'}}> 
                                <Button variant="secondary" style={{marginRight:10}} onClick={()=>{
                                    this.props.closeModalRegister()
                                }}>Close</Button>
                                <Button
                                    onClick = {()=>{
                                        if(helper.isValidText(this.state.registerData.full_name)==false){
                                            helper.showError("Enter fullname");
                                            return;
                                        }
                                        else if(helper.isValidEmail(this.state.registerData.email)==false){
                                            helper.showError("Enter a valid email address");
                                            return;
                                        }
                                        else if(helper.isValidText(this.state.registerData.password)==false){
                                            helper.showError("Enter password");
                                            return;
                                        }
                                        else if(helper.isValidText(this.state.registerData.confirmpassword)==false){
                                            helper.showError("Confirm password");
                                            return;
                                        }
                                        else if(this.state.registerData.confirmpassword!=this.state.registerData.password){
                                            helper.showError("Password doesn't match");
                                            return;
                                        }
                                        else{
                                            
                                            this.setState({
                                                isloading:true
                                            })
                                            
                                            var dataRequest = {
                                                full_name: this.state.registerData.full_name,
                                                email: this.state.registerData.email,
                                                password: this.state.registerData.password,
                                                uapp: "AU",
                                                isProceed: "YES",
                                            };
                                    
                                            const http = new HttpService();
                                            http
                                                .postData(dataRequest, "register")
                                                .then((response) => {
                                                    this.setState({
                                                        isloading:false
                                                    })
                                                    console.log("rr", response);
                                                    if (response.status == "success") {
                                                        localStorage.setItem("authkey", response.token);
                                                        localStorage.setItem("user", JSON.stringify(response.user));
                                                        helper.showSuccess("Account successfully created!");
                                                        this.props.registerdone();
        
                                                    } else {
                                                        if (response.errors != undefined) {
                                                            var errorList = [];
                                                            for (var key in response.errors) {
                                                                if (response.errors.hasOwnProperty(key)) {
                                                                    var value = response.errors[key][0]
                                                                    errorList.push(value);
                                                                }
                                                            } 
                                                            helper.showError(errorList.join(","));
                                                        } else {
                                                            helper.showError(response.message);
                                                        }
                                                    }
                                                })
                                                .catch((error) => {
                                                    this.setState({
                                                        isloading:false
                                                    })
                                                    helper.showError("An error occured!")
                                                });
                                        }
                                    }} 
                                    variant="success"
                                >Register</Button>
                            </div>
                        </div>   
                    </Modal.Footer>
                    </div>
                
                </Modal>


        )

    }

}
export default Register;