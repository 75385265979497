import React, { useState, useEffect } from "react";
import ClipLoader from "react-spinners/ClipLoader";
import { css } from "@emotion/react";
import Modal from 'react-modal';



function ModalLoading(props) {
    const override = css`
        display: block;
        margin: 0 auto;
        margin-bottom: 30px;
    `;

    
	const modalStyle = {
		content: {
			top: "50%",
			left: "50%",
			right: "auto",
			bottom: "auto",
			marginRight: "-50%",
			transform: "translate(-50%, -50%)",
            backgroundColor:'transparent',
            borderWidth:0,
		}
	};

    const innerModal = {
        //backgroundColor: "red", 
        margin: -20, 
        width: 200, 
        padding: 20, 
        position: "relative", 
        /*borderRadius: 5, 
        borderWidth: 1, 
        borderColor: "#EEEEEE", 
        borderStyle: "solid"
        */
    }
	

    const closebtn = {
        position: "absolute", 
        top: 0, 
        right: 0, 
        backgroundColor: "transparent", 
        borderRadius: "50%", 
        borderColor: "#EEEEEE", 
        borderWidth: 1
    }

    const modalContent = {
        display: "flex", 
        alignItems: "center", 
        flexDirection: "column", 
        justifyContent: "center" 
    }

    return (
        <Modal 
				isOpen={props.modalIsOpen} 
				style={modalStyle} 
				ariaHideApp={false}
				overlayClassName="myoverlay"
		>
                
            <div style={innerModal}>
                {
                    props.disablecancel != true ?
                    <button
                        style={closebtn}
                        onClick={() => {
                            props.closemodal();
                        }}
                    >
                        x
                    </button>
                    : null
                }
               

                <div style={modalContent}>
                    <ClipLoader css={override} size={60} color={"gray"} loading={true} />
                </div>
            </div>
        
        </Modal>
    );
}

export default ModalLoading;
