import React, { Component } from "react";
import { Link } from 'react-router-dom';
import { FiX , FiMenu } from "react-icons/fi";
import {Modal, Button, Form} from "react-bootstrap";
import "./style.css";
import { FaSpinner } from 'react-icons/fa';
import helper from "../../store/Helper";
import ModalLoading from "../ModalLoading";
import HttpService from "../../store/HttpServices";
import Select from 'react-select'
import ApplyBtn from "./ApplyBtn";
import TwoTApplyBtn from "./TwoTApplyBtn";
import OneTApplyBtn from "./OneTApplyBtn";
import ApplyCheckBox from "./ApplyCheckBox";
import OneT from "./OneT";

const options = [
    { 
        label: 'Bulk Search and Replace',
        value : {
            code: "FR",
            desc: "Bulk Search and Replace"
        } 
    },
    { 
        label: 'Remove Html Tag and Text',
        value : {
            code: "RTT",
            desc: "Remove html tags with everything within"
        } 
    },
    { 
        label: 'Remove Html Tag Only',
        value : {
            code: "RTO",
            desc: "Remove html tags but preserve texts within."
        } 
    },
    { 
        label: 'Replace Html Tag with Another',
        value : {
            code: "RTWAT",
            desc: "Find tag(s) and replace them with another."
        } 
    },
    { 
        label: 'Paragraph and Numbering',
        value : {
            code: "PARANUM",
            desc: "Apply paragraph and numbering to texts."
        } 
    },
    { 
        label: 'Hyperlinking',
        value : {
            code: "HYPE",
            desc: "Set an identifier and Hyperlink anchors with URL"
        } 
    },
    { 
        label: 'Hyperlinking Styling',
        value : {
            code: "HYPESTYL",
            desc: "Pass a URL and add or remove new tab and rel attributes"
        } 
    },
    { 
        label: 'Serial Text Transformer',
        value : {
            code: "SSAR",
            desc: "Set an identifier and do a serial text transformation."
        } 
    },
    { 
        label: 'Numbered Paragraphs Styling',
        value : {
            code: "AEONP",
            desc: "Style numbered paragraphs the way you want."
        } 
    }
    
]

class FunctionSearch extends Component{

    state = {
        description: "",
        code:"",

        txtbox1:"",
        txtbox2:"",

        chkbox1:false,
        chkbox2:false,
        chkbox3:false,
        chkbox4:false,
        chkbox5:false,
        chkbox6:false,
        chkbox7:false,
        chkbox8:false,
    }
    

    render(){
        return(
        
                 <Modal
                    show={this.props.modalRegister}
                    onHide={()=>this.props.closemodal()}
                    centered

                >
                    <div style={{pointerEvents: this.state.isloading == true ? 'none' : 'auto'}}> 
                        <Modal.Header closeButton style={{marginBottom:-40}}>
                            <Modal.Title>
                            Search for a function<br/>
                            <span style={{fontSize:14, margin:0, padding:0}}>You can always apply multiple functions to the input</span>
                            </Modal.Title>
                        </Modal.Header>

                        <Modal.Body>
                            <Form>

                                <Select 
                                    options={options} 
                                    onChange={(e)=>{
                                        let value = e.value;
                                        if(value.code=="PARANUM"){
                                            this.setState({
                                                chkbox1:true,
                                                chkbox2:true,
                                                chkbox3:true
                                            })
                                        }
                                        else if(value.code=="HYPE"){
                                            this.setState({
                                                chkbox1:true,
                                                chkbox2:true,
                                                chkbox3:true,
                                                chkbox4:false,
                                                chkbox5:false,
                                                chkbox6:false,
                                                chkbox7:false,
                                                chkbox8:false,
                                            })
                                        }

                                        //console.log(value.desc);
                                        this.setState({
                                            description:value.desc,
                                            code: value.code,
                                        })

                                    }}
                                />

                                {
                                    this.state.code !="" ?
                                    <p style={{padding:`10px 0px 0px`}}>{this.state.description}</p>
                                    : null
                                }
                                
                                
                                {
                                    this.state.code == "FR" ? 
                                        <TwoTApplyBtn
                                            title1 = "Text to find (For multiple, separate texts by | )"
                                            txt1val = {(val)=>{
                                                this.setState({txtbox1: val})
                                            }}
                                            title2 = "Replace with (For multiple, separate texts by | )"
                                            txt2val = {(val)=>{
                                                this.setState({txtbox2: val})
                                            }}
                                            btnClicked = {()=>{
                                                if(helper.isValidText(this.state.txtbox1)==false){
                                                    helper.showError("Enter text to find");
                                                    return false;
                                                }
                                                /*
                                                if(helper.isValidText(this.state.txtbox2)==false){
                                                    helper.showError("Enter text to replace with");
                                                    return false;
                                                }
                                                */
                                                let rulegenerated = `FR⏭️${this.state.txtbox1}⏭️${this.state.txtbox2}`;
                                                this.props.saved(rulegenerated);
                                                this.setState({
                                                    txtbox1:"",
                                                    txtbox2:"",
                                                    code:""
                                                })
                                            }}
                                        />

                                    : null
                                }


                                {
                                    this.state.code == "RTT" ? 
                                       <OneTApplyBtn
                                            title1 = "Separate tags by | (e.g. span|b|li )"
                                            txt1val = {(val)=>{
                                                this.setState({txtbox1: val})
                                            }}
                                            
                                            btnClicked = {()=>{
                                                if(helper.isValidText(this.state.txtbox1)==false){
                                                    helper.showError("Enter tag list");
                                                    return false;
                                                }
                                                let rulegenerated = `RTT⏭️${this.state.txtbox1}`;
                                                this.props.saved(rulegenerated);
                                                this.setState({
                                                    txtbox1:"",
                                                    txtbox2:"",
                                                    code:""
                                                })
                                            }}
                                       />
                                    : null
                                }


                                {
                                    this.state.code == "RTO" ? 
                                       <OneTApplyBtn
                                            title1 = {`Separate tags by | (e.g. span|b|li )`}
                                            txt1val = {(val)=>{
                                                this.setState({txtbox1: val})
                                            }}
                                            btnClicked = {()=>{
                                                if(helper.isValidText(this.state.txtbox1)==false){
                                                    helper.showError("Enter tag list to be removed");
                                                    return false;
                                                }
                                                
                                                let rulegenerated = `RTO⏭️${this.state.txtbox1}`;
                                                this.props.saved(rulegenerated);
                                                this.setState({
                                                    txtbox1:"",
                                                    txtbox2:"",
                                                    code:""
                                                })
                                            }}
                                       />
                                    : null
                                }


                                {
                                    this.state.code == "RTWAT" ? 
                                       <TwoTApplyBtn
                                            title1 = "Tag to find (e.g span)"
                                            txt1val = {(val)=>{
                                                this.setState({txtbox1: val})
                                            }}
                                            title2 = "Tag to replace with (e.g h1 )"
                                            txt2val = {(val)=>{
                                                this.setState({txtbox2: val})
                                            }}
                                            btnClicked = {()=>{
                                                if(helper.isValidText(this.state.txtbox1)==false){
                                                    helper.showError("Enter tag to find");
                                                    return false;
                                                }
                                                if(helper.isValidText(this.state.txtbox2)==false){
                                                    helper.showError("Enter tag to replace with");
                                                    return false;
                                                }
                                                let rulegenerated = `RTWAT⏭️${this.state.txtbox1}⏭️${this.state.txtbox2}`;
                                                this.props.saved(rulegenerated);
                                                this.setState({
                                                    txtbox1:"",
                                                    txtbox2:"",
                                                    code:""
                                                })
                                            }}
                                       />
                                    : null
                                }


                                {
                                    this.state.code == "HYPESTYL" ?
                                    <div>
                                       

                                        <ApplyCheckBox
                                            title = "Open on New Tab"
                                            checkboxval = {(val)=>{
                                                this.setState({
                                                    chkbox1:val
                                                })
                                            }}
                                            checkedval = {this.state.chkbox1}
                                        />

                                        
                                        <ApplyCheckBox
                                            title = {`Apply Rel="no follow"`}
                                            checkboxval = {(val)=>{
                                                this.setState({
                                                    chkbox2:val
                                                })
                                            }}
                                            checkedval = {this.state.chkbox2}
                                        />
                                     
                                        <ApplyCheckBox
                                            title = {`Apply Rel="Sponsored"`}
                                            checkboxval = {(val)=>{
                                                this.setState({
                                                    chkbox3:val
                                                })
                                            }}
                                            checkedval = {this.state.chkbox3}
                                        />
                                        
                                       
                                        
                                        <ApplyBtn
                                            btnClicked = {()=>{
                                                let rulegenerated = `HYPESTYL⏭️${this.state.chkbox1}⏭️${this.state.chkbox2}⏭️${this.state.chkbox3}`;
                                                this.setState({
                                                    code:"",
                                                    chkbox1:false,
                                                    chkbox2:false,
                                                    chkbox3:false,
                                                })
                                                this.props.saved(rulegenerated);
                                            }}
                                        />
                                    </div>
                                    : null
                                }



                                {
                                    this.state.code == "PARANUM" ?
                                    <div>
                                       

                                        <ApplyCheckBox
                                            title = "Apply paragraph"
                                            checkboxval = {(val)=>{
                                                this.setState({
                                                    chkbox1:val
                                                })
                                            }}
                                            checkedval = {this.state.chkbox1}
                                        />

                                        
                                        <ApplyCheckBox
                                            title = "Apply Numbering"
                                            checkboxval = {(val)=>{
                                                this.setState({
                                                    chkbox2:val
                                                })
                                            }}
                                            checkedval = {this.state.chkbox2}
                                        />
                                     
                                        <ApplyCheckBox
                                            title = "Don't Number Empty Space"
                                            checkboxval = {(val)=>{
                                                this.setState({
                                                    chkbox3:val
                                                })
                                            }}
                                            checkedval = {this.state.chkbox3}
                                        />
                                        
                                       
                                        
                                        <ApplyBtn
                                            btnClicked = {()=>{
                                                let rulegenerated = `PARANUM⏭️${this.state.chkbox1}⏭️${this.state.chkbox2}⏭️${this.state.chkbox3}`;
                                                this.props.saved(rulegenerated);
                                            }}
                                        />
                                    </div>
                                    : null
                                }


                                {
                                    this.state.code == "HYPE" ? 
                                    <div style={{marginTop:-15}}> 
                                        <OneT
                                            title = "Identifier (e.g # )"
                                            txtval = {(val)=>{
                                                this.setState({txtbox1: val})
                                            }}
                                        />

                                        <OneT
                                            title = "URL to use"
                                            txtval = {(val)=>{
                                                this.setState({txtbox2: val})
                                            }}
                                        />

                                        <ApplyCheckBox
                                            title = "Treat hyphen and underscore as one word"
                                            checkboxval = {(val)=>{
                                                this.setState({
                                                    chkbox1:val
                                                })
                                            }}
                                            checkedval = {this.state.chkbox1}
                                        />

                                        <ApplyCheckBox
                                            title = "Make url lowercase"
                                            checkboxval = {(val)=>{
                                                this.setState({
                                                    chkbox2:val
                                                })
                                            }}
                                            checkedval = {this.state.chkbox2}
                                        />

                                        <ApplyCheckBox
                                            title = "Ignore Identifier In Anchor Tag"
                                            checkboxval = {(val)=>{
                                                this.setState({
                                                    chkbox3:val
                                                })
                                            }}
                                            checkedval = {this.state.chkbox3}
                                        />

                                        <ApplyCheckBox
                                            title = "Ignore Hypen In Anchor Tag"
                                            checkboxval = {(val)=>{
                                                this.setState({
                                                    chkbox4:val
                                                })
                                            }}
                                            checkedval = {this.state.chkbox4}
                                        />


                                        <ApplyCheckBox
                                            title = "Ignore Underscore In Anchor Tag"
                                            checkboxval = {(val)=>{
                                                this.setState({
                                                    chkbox5:val
                                                })
                                            }}
                                            checkedval = {this.state.chkbox5}
                                        />

                                        <ApplyCheckBox
                                            title = "Open on new tab"
                                            checkboxval = {(val)=>{
                                                this.setState({
                                                    chkbox6:val
                                                })
                                            }}
                                            checkedval = {this.state.chkbox6}
                                        />

                                        <ApplyCheckBox
                                            title = 'Add rel="no follow"'
                                            checkboxval = {(val)=>{
                                                this.setState({
                                                    chkbox7:val
                                                })
                                            }}
                                            checkedval = {this.state.chkbox7}
                                        />

                                        <ApplyCheckBox
                                            title = 'Add rel="sponsored"'
                                            checkboxval = {(val)=>{
                                                this.setState({
                                                    chkbox8:val
                                                })
                                            }}
                                            checkedval = {this.state.chkbox8}
                                        />


                                        <ApplyBtn
                                            btnClicked = {()=>{
                                                if(helper.isValidText(this.state.txtbox1)==false){
                                                    helper.showError("Enter Identifier");
                                                    return false;
                                                }
                                                if(helper.isValidText(this.state.txtbox2)==false){
                                                    helper.showError("Enter URL to replace with");
                                                    return false;
                                                }
                                                let rulegenerated = `HYPE⏭️${this.state.txtbox1}⏭️${this.state.txtbox2}⏭️${this.state.chkbox1}⏭️${this.state.chkbox2}⏭️${this.state.chkbox3}⏭️${this.state.chkbox4}⏭️${this.state.chkbox5}⏭️${this.state.chkbox6}⏭️${this.state.chkbox7}⏭️${this.state.chkbox8}`;
                                                this.props.saved(rulegenerated);
                                                this.setState({
                                                    txtbox1:"",
                                                    txtbox2:"",
                                                    code:""
                                                })
                                            }}
                                        />
                                    </div>

                                      
                                    : null
                                }



                                {
                                    this.state.code == "SSAR" ? 
                                       <TwoTApplyBtn
                                            title1 = "Identifier"
                                            txt1val = {(val)=>{
                                                this.setState({txtbox1: val})
                                            }}
                                            title2 = "Transformers - Separate transformers by | (e.g. write|sleep )"
                                            txt2val = {(val)=>{
                                                this.setState({txtbox2: val})
                                            }}
                                            btnClicked = {()=>{
                                                if(helper.isValidText(this.state.txtbox1)==false){
                                                    helper.showError("Enter Identifier");
                                                    return false;
                                                }
                                                if(helper.isValidText(this.state.txtbox2)==false){
                                                    helper.showError("Enter transformers");
                                                    return false;
                                                }
                                                let rulegenerated = `SSAR⏭️${this.state.txtbox1}⏭️${this.state.txtbox2}`;
                                                this.props.saved(rulegenerated);
                                                this.setState({
                                                    txtbox1:"",
                                                    txtbox2:"",
                                                    code:""
                                                })
                                            }}
                                       />
                                    : null
                                }


                                {
                                    this.state.code == "AEONP" ? 
                                       <OneTApplyBtn
                                            title1 = {`Styling (e.g. b, span, h1, etc )`}
                                            txt1val = {(val)=>{
                                                this.setState({txtbox1: val})
                                            }}
                                            btnClicked = {()=>{
                                                if(helper.isValidText(this.state.txtbox1)==false){
                                                    helper.showError("Enter effect to add");
                                                    return false;
                                                }
                                                
                                                let rulegenerated = `AEONP⏭️${this.state.txtbox1}`;
                                                this.props.saved(rulegenerated);
                                                this.setState({
                                                    txtbox1:"",
                                                    txtbox2:"",
                                                    code:""
                                                })
                                            }}
                                       />
                                    : null
                                }

                                
                                
                            </Form>
                        </Modal.Body>

                       
                    </div>
                
                </Modal>


        )

    }

}
export default FunctionSearch;