import React, { Component } from "react";
import PageHelmet from "../../component/common/Helmet";
import ScrollToTop from "react-scroll-up";
import { FiChevronUp } from "react-icons/fi";
import Footer from "../Footer/Footer";
import "./style.css";
import helper from "../../store/Helper";
import Register from "../Register/Register";
import Login from "../Login/Login";
import HttpService from "../../store/HttpServices";
import functionlist from "../../store/functionlist";
import { Link } from "react-router-dom";
import DuCopyFunctionBox from "../Subpagesfunc/DuCopyFunctionBox";
import Ducopyheader from "../Ducopyhome/Ducopyheader";
import Ducopymenus from "../Ducopyhome/Ducopymenus";
import { connect } from "react-redux";

const Playground = () => {
	return (
		<>
			<PageHelmet pageTitle="Homepage" />

			<div className="container">
				<Ducopyheader />

				
				<div className="row">
					<div className="col-lg-12">
						<div className="text-left" style={{ padding: `20px 0px` }}>
							<h2
								className="title text-center"
								style={{ fontSize: 40, color: "#226E93", marginBottom: 0 }}
							>
								Playground
							</h2>
						</div>
					</div>
				</div>
				

			</div>

			<DuCopyFunctionBox />

			<Ducopymenus />
			<br />
			<br />

			<div className="backto-top">
				<ScrollToTop showUnder={160}>
					<FiChevronUp />
				</ScrollToTop>
			</div>

			<Footer />
		</>
	);
};

export default Playground;
