import React, { useState, useEffect } from "react";
function OneT(props) {
    


    return (
        <div>
            <label>{props.title}</label>
            <input
                type="text"
                onChange={(e)=>{
                    props.txtval(e.target.value)
                }}
                
            />
        </div>
    );
}

export default OneT;
