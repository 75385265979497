import React, { useState, useEffect } from "react";


function ApplyBtn(props) {
    


    return (
        <button
            onClick={()=>{
                props.btnClicked()
            }} 
            className="btn-default" 
            type="button"
            style={{letterSpacing:'0px', fontWeight:'bold', width:'100%', textTransform:'none', borderRadius:4, padding:8, marginTop:10}}
        >
            Apply
        </button>
    );
}

export default ApplyBtn;
