import { createStore, combineReducers, applyMiddleware } from "redux";

import thunk from "redux-thunk";

import gReducer from "./reducers/gReducer";

const rootReducer = combineReducers({
  gReducer: gReducer,
});

const configureStore = () => {
  return createStore(rootReducer, applyMiddleware(thunk));
};

export default configureStore;
