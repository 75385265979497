import React, { useState, useEffect } from "react";
function TwoTApplyBtn(props) {
    


    return (
        <div style={{marginTop:-15}}>
            <label>{props.title1}</label>
            <input
                type="text"
                onChange={(e)=>{
                    props.txt1val(e.target.value)
                }}
                
            />

            <label>{props.title2}</label>
            <input
                type="text"
                onChange={(e)=>{
                    props.txt2val(e.target.value)
                }}
                
            />

            <button
                onClick={()=>{
                    props.btnClicked()
                }} 
                className="btn-default" 
                type="button"
                style={{letterSpacing:'0px', fontWeight:'bold', width:'100%', textTransform:'none', borderRadius:4, padding:8, marginTop:10}}
            >
                Apply
            </button>

        </div>
    );
}

export default TwoTApplyBtn;
