import React, { useContext, useState, useEffect } from "react";
import { Link } from 'react-router-dom';
import { FiX , FiMenu } from "react-icons/fi";
import {Modal, Button, Form} from "react-bootstrap";
import { FaSpinner } from 'react-icons/fa';
import helper from "../../store/Helper";
import ModalLoading from "../ModalLoading";
import HttpService from "../../store/HttpServices";
import { connect } from "react-redux";
import {setuserauth, setrules, showforgotpassword} from "../../store/actions"
import { useDispatch, useSelector } from "react-redux";
import { useHistory } from "react-router-dom";

const ForgotPassword = () => {

    const [loading, setLoading] = useState(null);
    const dispatch = useDispatch();
    const modalshowforgotpassword = useSelector((state) => state.gReducer.showforgotpassword);

    const [modalIsOpen, setModalIsOpen] = useState(false);
	const [userInputData, setUserInputData] = useState(false);
	const [btnText, setBtnText] = useState("Send OTP");
	const [currentAction, setCurrentAction] = useState("");
	const [isPasswordV, setIsPasswordV] = useState("");

    const history = useHistory();

    const resetpassword = (pcurrent) => {
		setLoading(true);
		setModalIsOpen(true);
		var dataRequest = {
			email: userInputData.email,
			resetphase: pcurrent,
			validcode: userInputData.validcode,
			newpassword: userInputData.newpassword,
		};

		const http = new HttpService();
		http
			.postData(dataRequest, "passwordreset")
			.then((response) => {
				setLoading(false);
				setModalIsOpen(false);
				console.log("rr", response);
				console.log("pcurrent", pcurrent)

				if (response.status == "success") {
					if (pcurrent == "REQ") {
						setCurrentAction("REQ");
						setBtnText("Validate OTP");
						helper.showSuccess(
							"OTP Sent! Check your email address for the OTP Code"
						);
					}
					if (pcurrent == "VER" && isPasswordV != "YES") {
						setIsPasswordV("YES");
						setBtnText("Update Password");
					}
					if (pcurrent == "VER" && isPasswordV == "YES") {
						helper.showSuccess("Password reset successful!");
                        dispatch(showforgotpassword(false));
					}
				} else {
					helper.showError(response.message);
				}
			})
			.catch((error) => {
				setLoading(false);
				setModalIsOpen(false);
				helper.showError("An error occured!");
			});
	};

	const handleChange = (event) => {
		//setErrorMessage([]);
		var objectkey = event.target.name;
		var objectvalue = event.target.value;
		var oldData = Object.assign({}, userInputData);
		oldData[objectkey] = objectvalue;
		setUserInputData(oldData);
	};

	const getCurrentMessage = (cmode, cpassentering) => {
		let textmsg = "";
		if (cmode == "") {
			textmsg =
				"A One Time password will be sent to your email. Please enter the email associated with your account";
		} else if (cmode == "REQ" && cpassentering != "YES") {
			textmsg = "Enter the OTP Code sent to your email address";
		} else if (cmode == "REQ" && cpassentering == "YES") {
			textmsg = "Enter your new password";
		}
		return textmsg;
	};


    return(    
            <Modal
                show={modalshowforgotpassword}
                backdrop="static"
                keyboard={false}
                centered
            >
                <div style={{pointerEvents: loading == true ? 'none' : 'auto'}}> 
                        <Modal.Header 
                            //closeButton
                        >
                        <Modal.Title>Forgot Password</Modal.Title>
                    </Modal.Header>

                    <Modal.Body>

                        <Form>
                            <Form.Label style={{fontWeight:'bold', color:"#226E93"}}>{getCurrentMessage(currentAction, isPasswordV)}</Form.Label>

                            <Form.Group controlId="formBasicEmail">

                            {currentAction == "" ? (
                                <div>
                                    <label>Email</label>
                                    <input
                                        type="email"
                                        className="emailInput"
                                        name="email"
                                        placeholder="Enter your email"
                                        required=""
                                        onChange={(event) => handleChange(event)}
                                        style={{ width: "100%" }}
                                    />
                                </div>
                            ) : null}


                            {currentAction == "REQ" && isPasswordV != "YES" ? (
                                <div>
                                    <label>OTP (Check your mailbox for OTP Code)</label>
                                    <input
                                        type="text"
                                        className="nameInput"
                                        name="validcode"
                                        placeholder="Enter OTP Code"
                                        required=""
                                        onChange={(event) => handleChange(event)}
                                        style={{ width: "100%" }}
                                    />
                                </div>
                            ) : null}


                            {currentAction == "REQ" && isPasswordV == "YES" ? (
                                <div>
                                    <label>Enter password</label>
                                    <input
                                        type="password"
                                        className="passwordInput"
                                        name="newpassword"
                                        placeholder="Enter Password"
                                        required=""
                                        onChange={(event) => handleChange(event)}
                                        style={{ width: "100%" }}
                                    />

                                    <label>Re-type password</label>
                                    <input
                                        type="password"
                                        className="passwordInput"
                                        name="retypepassword"
                                        placeholder="Retype Password"
                                        required=""
                                        onChange={(event) => handleChange(event)}
                                        style={{ width: "100%" }}
                                    />
                                </div>
                            ) : null}


                            
                            </Form.Group>

                        </Form>
                    </Modal.Body>

                    <Modal.Footer>
                    <div className="loginCFooter">
                        <div>
                        </div>
                        
                        {
                            loading == true ?
                            <FaSpinner icon="spinner" className="spinner" />
                            : null
                        }
                        
                        <div style={{flex:1, display:'flex', flexDirection:'row', justifyContent:'flex-end'}}> 
                            
                            <Button
                                onClick={() => {
                                    if (currentAction == "") {
                                        if (helper.isValidEmail(userInputData.email) == false) {
                                            helper.showError("Please enter a valid email");
                                            return false;
                                        } else {
                                            resetpassword("REQ");
                                        }
                                    } else if (currentAction == "REQ" && isPasswordV != "YES") {
                                        if (helper.isValidText(userInputData.validcode) == false) {
                                            helper.showError("Please enter a valid OTP Code");
                                            return false;
                                        } else {
                                            resetpassword("VER");
                                        }
                                    } else if (currentAction == "REQ" && isPasswordV == "YES") {
                                        if (helper.isValidText(userInputData.newpassword) == false) {
                                            helper.showError("Enter new password");
                                            return false;
                                        } else if (
                                            helper.isValidText(userInputData.retypepassword) == false
                                        ) {
                                            helper.showError("Retype your new password");
                                            return false;
                                        } else if (
                                            userInputData.newpassword != userInputData.retypepassword
                                        ) {
                                            helper.showError("Password does not match");
                                            return false;
                                        } else {
                                            resetpassword("VER");
                                        }
                                    }
                                }}
                                variant="success"
                            >{btnText}</Button>
                            &nbsp;&nbsp;&nbsp;
                                
                            <Button variant="secondary" style={{marginRight:10}} onClick={()=>{
                                dispatch(showforgotpassword(false));
                            }}>Close</Button>

                        </div>
                    </div>   
                </Modal.Footer>
                </div>
            
            </Modal>


    )

    

}



export default ForgotPassword;