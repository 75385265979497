import FingerprintJS from "@fingerprintjs/fingerprintjs";

class HttpService {
	getCurrentHostUrl = () => {
		if (
			window.location.host.indexOf("localhost") >= 0 ||
			window.location.host.indexOf("127.0.0.1") >= 0
		) {
			return "https://app.ducopy.com/api/";
		} else {
			return "https://app.ducopy.com/api/";
		}
	};

	getData = function (urlEndPoint) {
		let requestOptions = {
			//'mode': 'no-cors',
			method: "GET",
			headers: {
				"Content-Type": "application/json",
			},
		};

		return fetch(this.getCurrentHostUrl() + urlEndPoint, requestOptions).then(
			(response) => response.json()
		);
	};

	postData = async (data, urlEndPoint) => {
		const fpPromise = FingerprintJS.load();
		const fp = await fpPromise;
		const result = await fp.get();
		const visitorId = result.visitorId;
		const bearertoken = localStorage.getItem("authkey");
		data.fingerprint = visitorId;
		let requestOptions = {
			//'mode': 'no-cors',
			method: "POST",
			headers: {
				"Content-Type": "application/json",
				Authorization: "Bearer " + bearertoken,
			},
			body: JSON.stringify(data),
		};

		console.log("appreq", requestOptions);

		return fetch(this.getCurrentHostUrl() + urlEndPoint, requestOptions).then(
			(response) => response.json()
		);
	};
}

export default HttpService;
