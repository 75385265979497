import React, { Component } from "react";
import { Link } from "react-router-dom";
import { FiX, FiMenu } from "react-icons/fi";

class Header extends Component {
	constructor(props) {
		super(props);
		this.menuTrigger = this.menuTrigger.bind(this);
		this.CloseMenuTrigger = this.CloseMenuTrigger.bind(this);
		window.addEventListener("load", function () {
			console.log("All assets are loaded");
		});
	}
	menuTrigger() {
		document.querySelector(".header-wrapper").classList.toggle("menu-open");
	}
	CloseMenuTrigger() {
		document.querySelector(".header-wrapper").classList.remove("menu-open");
	}

	state = {
		modalRegister: false,
		modalLogin: false,
	};

	handleChange = (event) => {
		var objectkey = event.target.name;
		var objectvalue = event.target.value;
		var oldData = Object.assign({}, this.state.registerData);
		oldData[objectkey] = objectvalue;
		this.setState({
			registerData: oldData,
		});
	};

	render() {
		var elements = document.querySelectorAll(".has-droupdown > a");
		for (var i in elements) {
			if (elements.hasOwnProperty(i)) {
				elements[i].onclick = function () {
					this.parentElement
						.querySelector(".submenu")
						.classList.toggle("active");
					this.classList.toggle("open");
				};
			}
		}
		const { logo, color = "default-color" } = this.props;
		let logoUrl = <img src="/assets/img/ducopylogo.png" alt="DuCopy" />;

		return (
			<header
				className={`header-area formobile-menu header--transparent ${color}`}
			>
				<div className="header-wrapper" id="header-wrapper">
					<div className="header-left">
						<div className="logo">
							<a href="/">{logoUrl}</a>
						</div>
					</div>
					<div className="header-right">
						<nav className="mainmenunav d-lg-block">
							<ul className="mainmenu">
								{/** 
                                <li><Link to="/contact" >Ducopy Central</Link></li>
                                */}
							</ul>
						</nav>

						<div
							className="header-btn"
							onClick={() => {
								this.props.loginregister();
							}}
						>
							<Link to="#" className="btn-default btn-border btn-opacity">
								{this.props.authStatus == true ? <span>Log out</span> : null}

								{this.props.authStatus == false ? <span>Log In</span> : null}
							</Link>
						</div>
						{/* Start Humberger Menu  */}
						<div className="humberger-menu d-block d-lg-none pl--20 pl_sm--10">
							<span
								onClick={this.menuTrigger}
								className="menutrigger text-white"
							>
								<FiMenu />
							</span>
						</div>
						{/* End Humberger Menu  */}
						<div className="close-menu d-block d-lg-none">
							<span onClick={this.CloseMenuTrigger} className="closeTrigger">
								<FiX />
							</span>
						</div>
					</div>
				</div>
			</header>
		);
	}
}
export default Header;
