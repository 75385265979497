import React, { Component } from "react";
import ReactDOM from "react-dom";

import "./index.scss";
import "./index.css";

import { BrowserRouter, Switch, Route, StaticRouter } from "react-router-dom";
// import * as serviceWorker from "./serviceWorker";
import { ToastContainer, toast } from "react-toastify";
import { ToastProvider, useToasts } from "react-toast-notifications";
import Ducopyactualhome from "./pages/Ducopyhome/index";
import SinglePage from "./pages/Subpagesfunc/SinglePage";
import { Provider } from "react-redux";
import configureStore from "../src/store/configureStore";
import Dashboard from "./pages/Dashboard";
import Playground from "./pages/Playground";
import ContactPage from "./pages/Contact";
import FeatureRequest from "./pages/FeatureRequest"
import MySidebarMenu from "./pages/Sidebar";
import error404 from './pages/error404';

const store = configureStore();

class Root extends Component {

	componentDidMount(){
		console.log("something",this.props)
		
	}

	render() {
		return (
			<div>
				<BrowserRouter basename={"/"}>
					<Switch>
						<Route
							exact
							path={`${process.env.PUBLIC_URL}/`}
							component={Ducopyactualhome}
						/>
						
						<Route
							exact
							path={`${process.env.PUBLIC_URL}/dashboard`}
							component={Dashboard}
						/>
						<Route
							exact
							path={`${process.env.PUBLIC_URL}/playground`}
							component={Playground}
						/>
						<Route
							exact
							path={`${process.env.PUBLIC_URL}/contact`}
							component={ContactPage}
						/>
						<Route
							exact
							path={`${process.env.PUBLIC_URL}/featurerequest`}
							component={FeatureRequest}
						/>
						<Route
							exact
							path={`${process.env.PUBLIC_URL}/s/:seachurl?`}
							component={SinglePage}
						/>

						<Route 
							exact
							path={`${process.env.PUBLIC_URL}/blog`}
							component={() => { 
								if(!localStorage.getItem('firstLoad')){
									localStorage['firstLoad'] = true;
									window.location.reload();
									return null
								}  
								else{
									localStorage.removeItem('firstLoad');
									return null
								}
								
							}}
						/>

						{/* featurerequest */}

						{/* <Route
							exact
							path={`${process.env.PUBLIC_URL}/blog`}
							render= {(props)=>{
								window.location.reload(true);
							}}
						/> */}

						<Route path="*" component={error404} />

					
					</Switch>
				</BrowserRouter>

				

				<ToastContainer
					position="top-right"
					autoClose={2000}
					hideProgressBar={false}
					newestOnTop={false}
					closeOnClick
					rtl={false}
					pauseOnFocusLoss={false}
					draggable
					pauseOnHover={false}
				/>
			</div>
		);
	}
}

ReactDOM.render(
	<Provider store={store}>
		<Root />
	</Provider>,
	document.getElementById("root")
);
// serviceWorker.register();

//console.log = () => {};
//console.warn = () => {};
