import React, { useState, useEffect } from "react";


function ApplyCheckBox(props) {
    


    return (
        <div style={{display:'flex', flexDirection:'row', justifyContent:'space-between', marginTop:3}}> 
            <p>{props.title}</p>
            <input 
                type="checkbox" 
                style={{width:20, height:20}}
                onChange={(event)=>{
                    let val = event.target.checked;
                    props.checkboxval(val);
                }}
                checked = {props.checkedval}
            />
        </div>
    );
}

export default ApplyCheckBox;
