import React, { Component } from "react";
import PageHelmet from "../../component/common/Helmet";
import Footer from "../Footer/Footer";
import helper from "../../store/Helper";
import HttpService from "../../store/HttpServices";
import functionlist from "../../store/functionlist";
import DuCopyFunctionBox from "./DuCopyFunctionBox";
import "./style.css";
import Ducopyheader from "../Ducopyhome/Ducopyheader";
import Ducopymenus from "../Ducopyhome/Ducopymenus";
import { FaCode, FaArrowLeft, FaArrowRight } from "react-icons/fa";

class SinglePage extends Component{
    constructor(props){
        super(props);
        let result = helper.allurlpages(this.props.match.params.seachurl);

        console.log(result);

        this.state = {
            txtRuleGenerated:'',
            txtReplaceWith:'',
            txtStartWith:'',
            txtEndWith:'',

            ruleid:'',
            rulename:'',
            inputText:'',
            outputText:'',


            pagedata: result
        };
    }

    state = {
        modalRegister: false,
        modalLogin:false,
        modalfunctionsearch:false,
        
        authStatus: null,
        isloading:null,
        allrulesoptions:[],
    }

    componentDidMount(){ 
        helper.movetotop();
        this.checkauthValidity();
        //console.log(this.props.match.params.seachurl)
    }


    checkauthValidity = () => {
        let authId = localStorage.getItem("authkey");
        if(helper.isValidText(authId)==false){
            this.setState({
                authStatus:false
            })
        }
        else{
            //Some validity check
            this.setState({
                isloading:true
            })

            var dataRequest = {
                action: "isvalidauth",
            };
            const http = new HttpService();
            http
                .postData(dataRequest, "access1")
                .then((response) => {
                    this.setState({
                        isloading:false
                    })
                    console.log("rr", response);
                    if (response.status == "success") {
                        this.setState({
                            authStatus:true
                        })

                        //Fetch all saved rules
                        this.fetchAllSavedRules();

                    } else {
                        this.setState({
                            authStatus:false
                        })
                        localStorage.clear();
                    }
                })
                .catch((error) => {
                    this.setState({
                        isloading:false,
                        authStatus:false
                    })
                    localStorage.clear();
                });
        }
    }


    fetchAllSavedRules = () => {
        this.setState({
            isloading:true
        })

        var dataRequest = {
            action: "fetchAllRules",
            searchword:"",
        };
        const http = new HttpService();
        http
            .postData(dataRequest, "access1")
            .then((response) => {
                this.setState({
                    isloading:false
                })
               
                if (response.status == "success") {
                    this.setState({
                        authStatus:true,
                        allsavedrules: response.data
                    })

                    console.log("saved rules", response);
                    
                    let allrulesoptions = [];
                    let dataresponse = response.data;
                    if(dataresponse.length > 0){
                        dataresponse.forEach((item)=>{
                            let ruledata = item.ruledata;
                            ruledata = JSON.parse(ruledata);
                            allrulesoptions.push({
                                label:item.rulename,
                                value:{
                                    txtRuleGenerated: ruledata.txtRuleGenerated,
                                    rulename: item.rulename,
                                    ruleid: item.id
                                }
                            })
                        })

                        this.setState({
                            allrulesoptions:allrulesoptions
                        })
                        
                    }
                    
                } else {
                    this.setState({
                        authStatus:false
                    })
                    localStorage.clear();
                }
            })
            .catch((error) => {
                this.setState({
                    isloading:false,
                    authStatus:false
                })
                localStorage.clear();
            });
    }


    saveorUpdateRule = () => {
        if(this.state.authStatus==false){
            this.setState({
                modalLogin:true
            })
        }
        else{
            //proceed with the storage
            if(helper.isValidText(this.state.rulename)==false){
                helper.showError("Please enter rule name");
                return false;
            }

            if(helper.isValidText(this.state.txtRuleGenerated)==false){
                helper.showError("Please add some functions");
                return false;
            }

            var ruledata = {
                txtRuleGenerated: this.state.txtRuleGenerated
            }
            

            var dataRequest = {
                action: helper.isValidText(this.state.ruleid) == false ? "addNewRule" : "editRule",
                ruledata: JSON.stringify(ruledata),
                rulename: this.state.rulename,
                ruleid : this.state.ruleid,
            };

            this.setState({
                isloading:true
            })

            const http = new HttpService();
            http
                .postData(dataRequest, "access1")
                .then((response) => {
                    this.setState({
                        isloading:false
                    })
                    console.log("rr", response);

                    if (response.status == "success") {
                       helper.showSuccess("Successfully saved!");
                       helper.movetotop();
                       this.fetchAllSavedRules();
                    } else {
                        helper.showError("Unable to save rule. An error occured!")
                    }
                    
                    
                })
                .catch((error) => {
                    this.setState({
                        isloading:false,
                    })
                    helper.showError("An error occured!");
                    console.log("e", error);
                });


        }
    }


    deleteArule = () => {
        var dataRequest = {
            action: "deleteARule",
            ruleid : this.state.ruleid,
        };

        this.setState({
            isloading:true
        })

        const http = new HttpService();
        http
            .postData(dataRequest, "access1")
            .then((response) => {
                this.setState({
                    isloading:false
                })
                
                if (response.status == "success") {
                    helper.showSuccess("Successfully deleted!");
                    setTimeout(()=>{
                        window.location = "/"
                    }, 1000)
                    
                } else {
                    helper.showError("Unable to delete. An error occured!")
                }
            })
            .catch((error) => {
                this.setState({
                    isloading:false,
                })
                helper.showError("An error occured!");
                console.log("e", error);
            });
    }

    

    txtReplaceAction = ()=>{
        var inputTextData = this.state.inputText;
        try {
           
            var allrulesgenerated = this.state.txtRuleGenerated.split("\n");
            allrulesgenerated.forEach((item)=>{
                let itemgrabbedarray = item.split("⏭️");
                let codename = itemgrabbedarray[0];
                if(codename=="FR"){
                    inputTextData = functionlist.basicfindandreplace(inputTextData, itemgrabbedarray[1], itemgrabbedarray[2]);
                }
                else if(codename=="RTT"){
                    inputTextData = functionlist.removetagandtext(inputTextData, itemgrabbedarray[1]);
                }
                else if(codename=="RTO"){
                    inputTextData = functionlist.removetagandpreservetext(inputTextData, itemgrabbedarray[1]);
                }
                else if(codename=="RTWAT"){
                    inputTextData = functionlist.replacetagWithAnotherTag(inputTextData, itemgrabbedarray[1], itemgrabbedarray[2]);
                }
                else if(codename=="PARANUM"){
                    inputTextData = functionlist.paragraphandnumbering(inputTextData);
                }
                else if(codename=="HYPE"){
                    inputTextData = functionlist.identifierhyperlinking(inputTextData, itemgrabbedarray[1], itemgrabbedarray[2]);
                }
                else if(codename=="SSAR"){
                    inputTextData = functionlist.doASearialSearchAndReplace(inputTextData, itemgrabbedarray[1], itemgrabbedarray[2]);
                }
                else if(codename=="AEONP"){
                    inputTextData = functionlist.applyEffectOnNumberedParagraph(inputTextData, itemgrabbedarray[1]);
                }

            })
            
            this.setState({
                outputText: inputTextData
            })

        } catch (error) {
            this.setState({
                outputText: inputTextData
            })
        }
        

    }


    
    render(){
       

        return(
            <React.Fragment>
                <PageHelmet 
                    pageTitle='Homepage' 
                />


                   
                <div className="container">
                    <Ducopyheader/>

                    <div className="row">
                        <div className="col-lg-12">
                            <div className="text-left" style={{padding:`40px 0px`}}>
                                <h2 className="title text-center" style={{fontSize:40, color:"#226E93", marginBottom:0}}>{this.state.pagedata.title}</h2>
                            </div>
                        </div>
                    </div>

                    <div className="row">
                        <div className="col-lg-12">
                            <div className="section-title mb--30">
                                <div dangerouslySetInnerHTML={{ __html: this.state.pagedata.infodata}} />
                            </div>
                        </div>
                    </div>

                </div>
               
                {/** 
                <DuCopyFunctionBox/>
                */}

                    <div className="container">
                
                        <div className="rn-page-title text-left" style={{ display:'flex', alignItems:'center', justifyContent:'center', marginBottom:20}}>

                            <button
                                onClick={()=>{
                                    window.location = "/playground"
                                }} 
                                className="btn-default" type="button" style={{letterSpacing:'0px', fontWeight:'bold', width:'420px', textTransform:'none', borderRadius:4, cursor:'pointer'}}
                            >
                                    Go to playground &nbsp; &nbsp;  <FaArrowRight /> 
                            </button>

                         </div>
                    </div>

                <Ducopymenus/>
                <br/><br />

                    <div className="row">
						<div className="col-lg-12">
							<div className="text-left" style={{ padding: `50px 0px` }}>
								<h2
									className="title text-center"
									style={{
										fontSize: 30,
										padding: `20px 0px`,
										color: "#226E93",
										marginBottom: 0,
									}}
								>
									More Tools Coming Soon
								</h2>
								<p className="text-center">
									Do you have a special request? &nbsp; 
									<a href="/featurerequest"
										style={{color:"#FD4766", fontWeight:'bold'}}
									>Tell us here</a> and we will build
									it specially for you..
								</p>
							</div>
						</div>
					</div>
                    


                <Footer />
            </React.Fragment>
        )
    }
}
export default SinglePage