import PropTypes from "prop-types";
import React, { Component } from "react";
import { Helmet } from "react-helmet";

class PageHelmet extends Component {
	render() {
		return (
			<React.Fragment>
				<Helmet>
					<meta charSet="utf-8" />
					<title>DuCopy - Ultimate Text and String Transformer</title>
					<meta name="robots" content="noindex, follow" />
					<meta
						name="description"
						content="Du-copy - You copy it! Let’s du it!"
					/>
					<meta
						name="viewport"
						content="width=device-width, initial-scale=1, shrink-to-fit=no"
					/>
				</Helmet>
			</React.Fragment>
		);
	}
}
PageHelmet.propTypes = {
	title: PropTypes.string,
};
export default PageHelmet;
