import HttpService from "../HttpServices";
import { SET_AUTH, SET_RULES, SHOW_LOGIN, SHOW_REGISTER, SHOW_FORGOT_PASSWORD } from "./actionTypes";

export const setuserauth = (data) => {
    return {
        type: SET_AUTH,
        data: data,
    };
};


export const setrules = (data) => {
    return {
        type: SET_RULES,
        data: data,
    };
};

export const showlogin = (data) => {
    return {
        type: SHOW_LOGIN,
        data: data,
    };
};

export const showregister = (data) => {
    return {
        type: SHOW_REGISTER,
        data: data,
    };
};


export const showforgotpassword = (data) => {
    return {
        type: SHOW_FORGOT_PASSWORD,
        data: data,
    };
};