import moment from "moment";
import { ToastContainer, toast } from 'react-toastify';
import 'react-toastify/dist/ReactToastify.css';
import helper from "./Helper";

class FunctionsList {

    basicfindandreplace = (text, find, replace) => {
        find = find.split("|");
        replace = replace.split("|");
        
        for(let i=0; i<find.length; i++){
            let findtext = find[i];
            let replacetext = replace[i];
            if(helper.isValidText(replacetext)==false){
                replacetext = "";
            }

            text = text.replaceAll(findtext, replacetext);
        }

        return text;
    }

    removetagandtext = (inputString, wordstoremove)=>{
        ///const regexExp = new RegExp(` *\\${start}[^)]*\\${end} *`, 'g');
        //return this.replaceAll(text, regexExp, '');
        //return text.replaceAll(regexExp, "");
        //var wordstoremove = "p|img";

        let myString = inputString;
        try {
            wordstoremove = wordstoremove.split("|");
            wordstoremove.forEach((tagname)=>{
                tagname = tagname.toLowerCase();
                if(helper.isEmptyTag(tagname)==true){
                    let start = `<${tagname}`;
                    let noofoccurences = helper.getIndicesOf(start, myString);
    
                    noofoccurences.forEach(()=>{
                        let starttagloc = myString.toLowerCase().indexOf(start);
                        let endtagloc = myString.toLowerCase().indexOf('>', starttagloc);
                        let endtagloclength = 1;
                        let capturenextReplace =  myString.substring(starttagloc, endtagloc + endtagloclength);
                        myString = myString.replace(capturenextReplace, '');
                    })
    
                }
                else{
                    let start = `<${tagname}`;
                    let end = `</${tagname}>`;
                    let noofoccurences = helper.getIndicesOf(start, myString);
                    noofoccurences.forEach(()=>{
                        
                        let starttagloc = myString.toLowerCase().indexOf(start);
                        let endtagloc = myString.toLowerCase().indexOf(end);
                
                        let capturenextReplace =  myString.substring(starttagloc, endtagloc + end.length);
                        myString = myString.replace(capturenextReplace, '');
            
                    })
    
                }
            })
        } catch (error) {
            myString = inputString;
        }

        return myString;
    }

    removetagandpreservetext = (text, taglist) => {
        var listtoremove = taglist;
        if(listtoremove.toLowerCase().includes('li'.toLowerCase())){
            listtoremove = listtoremove+"|ol|ul";
        }

        const regexp = new RegExp(`<\\/?(${listtoremove})\\b[^<>]*>`, 'g')
        let  plaintext = text.replace(regexp, "");
        plaintext = plaintext.split("\n");

        let validtextList = [];
        plaintext.forEach((element) => {
            if(helper.isValidText(element)==true){
                element = element.trim();
                validtextList.push(element + "\n");
            }
        });
        return validtextList.join("");
    }

    paragraphandnumbering = (text, check1, check2, check3)=>{
        var brArray = text.split("\n");
        let newResult = [];

        let validnumber = 1;
        brArray.forEach((item, index)=>{

            check1 = helper.isBol(check1);
            check2 = helper.isBol(check2);
            check3 = helper.isBol(check3);

            //let isvalidtext = helper.isValidText(item);
            //newResult.push(`${index + 1}. ${item}` + "\n\n");

            //paragrah and number
            if(check1==true && check2==true){
                //numberemptyspace
                if(check3==false){
                    newResult.push(`${validnumber}. ${item}` + "\n\n");
                    validnumber++;
                }
                //Dont number empty space
                else{
                    if(helper.isValidText(item)==true){
                        newResult.push(`${validnumber}. ${item}` + "\n\n");
                        validnumber++;
                    }
                }
            }

            //paragrah and no number
            else if(check1==true && check2==false){
                if(check3==false){
                    newResult.push(`${item}` + "\n\n");
                }
                //Dont number empty space
                else{
                    if(helper.isValidText(item)==true){
                        newResult.push(`${item}` + "\n\n");
                    }
                }
            }


            //no paragrah but number
            else if(check1==false && check2==true){
                if(check3==false){
                    newResult.push(`${validnumber}. ${item}` + "\n");
                    validnumber++;
                }
                //Dont number empty space
                else{
                    if(helper.isValidText(item)==true){
                        newResult.push(`${validnumber}. ${item}` + "\n");
                        validnumber++;
                    }
                }
            }

            //not in any
            else{
                newResult.push(`${item}` + "\n\n");
            }
            
        })
        return newResult.join("");
    }

    identifierhyperlinking = (text, identifier, hyperlinkedurl, iswordcombined, isurllowercase, igidinanchor, ighypinanchor, igundinanchor, isnewtab, isnofollow, issposored)=>{
        try {
            iswordcombined = helper.isBol(iswordcombined);
            isurllowercase = helper.isBol(isurllowercase);
            igidinanchor = helper.isBol(igidinanchor);
            ighypinanchor = helper.isBol(ighypinanchor);
            igundinanchor = helper.isBol(igundinanchor);
            isnewtab = helper.isBol(isnewtab);
            isnofollow = helper.isBol(isnofollow);
            issposored = helper.isBol(issposored);
            
            let hashtagresults = [];
            if(iswordcombined==true){
                hashtagresults = text.split(/[\s\n\r]/gmi).filter(v=> v.startsWith(identifier))
            }
            else if(iswordcombined==false){
                var regexp1 = new RegExp(`\\B\\${identifier}\\w\\w+\\b`, 'g');
                hashtagresults = text.match(regexp1);
            }
        
        
            if(hashtagresults!=null && hashtagresults.length > 0){
                hashtagresults.forEach((item)=>{
        
                    let toreplacewith = `<a href="${hyperlinkedurl}${item.substring(1)}/" ${isnewtab==true ? 'target="_blank"': null} ${helper.determinerelval(isnewtab, isnofollow,issposored)}>${helper.trimmingitem(item, identifier, igidinanchor, ighypinanchor, igundinanchor)}</a>`;
        
                    
                    if(isurllowercase==true){
                        toreplacewith = toreplacewith.toLowerCase();
                    }
                    
                    text = text.replaceAll(item, toreplacewith);
        
                })
        
                return text;
            }
            else{
                return text;
            }
        } catch (error) {
            return text;
        }
    }


    hyperlinkingstyling = (instring,  istarget, isnofollow, isnosponsored) =>{

        istarget = helper.isBol(istarget);
        isnofollow = helper.isBol(isnofollow);
        isnosponsored = helper.isBol(isnosponsored);
  
        let noofoccurences = helper.getIndicesOf("<a", instring);
        
    
        let newstringresult = "";
        noofoccurences.forEach(()=>{
            let starttagloc = instring.toLowerCase().indexOf(`<a`);
            let endtagloc = instring.toLowerCase().indexOf('</a>');
            let endtagloclength =  endtagloc + 4;
    
            let capturenextReplace =  instring.substring(starttagloc,endtagloclength);
            let newreplacement = helper.determinerelvalhyperlinkstyling(capturenextReplace, istarget, isnofollow, isnosponsored);
            instring = instring.replace(capturenextReplace, newreplacement);
    
            //Let's cut some text out from index to last location 
            let newendindextag =  instring.toLowerCase().indexOf('</a>');
            newendindextag = newendindextag + 4;
            let texttocutout = instring.slice(0, newendindextag);
    
            //Add it to the string formation
            newstringresult = newstringresult+texttocutout;
    
            //Take it off from the actual main string
            instring = instring.replace(texttocutout, "");
            
        })
    
        newstringresult = newstringresult+instring
        return newstringresult;
    }  


    doASearialSearchAndReplace = (text, identifier, generateFor) => {
        //var identifier = "#husband";
        //var generateFor = "husband|wife|dad|mom";
        //let inputString = text.input;

        let newResultString = "";
        generateFor = generateFor.split("|");
        generateFor.forEach(element => {
            let newString = text.replaceAll(identifier, element);
            newResultString = newResultString + newString + "\n\n";
        });

        return newResultString;
    }

    applyEffectOnNumberedParagraph = (text, effecttoadd) =>{
        try {
            let stringval = text;
            let whattoadd = effecttoadd;
            let newResultString = [];
            stringval = stringval.split("\n");
            stringval.forEach((item)=>{
                if (!isNaN(item.charAt(0))==true && item.charAt(1)=="."){
                    item = `<${whattoadd}>${item}</${whattoadd}>`;
                }
                newResultString.push(item + "\n");
            })
            return newResultString.join("");
        } catch (error) {
            return text
        }
        
       
    }

    replacetagWithAnotherTag = (text, find, replacewith) => {
        //var find = "font";
        // = "h1";
        const regexExp = new RegExp(`<(\\/*)${find}[^>]*>`, 'g');
        return text.replaceAll(regexExp, `<$1${replacewith}>`);
    }

    
}
const functionlist = new FunctionsList();
export default functionlist;
