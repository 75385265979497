import React, {useState, useEffect} from "react";
import { Link } from "react-router-dom";
import { slide as Menu } from "react-burger-menu";
import "./style.css";
import { useDispatch, useSelector } from "react-redux";
import helper from "../../store/Helper";
import {setuserauth, setrules, showforgotpassword, showlogin} from "../../store/actions"
import { AiOutlineLogin, AiOutlineLogout } from "react-icons/ai";


const MySidebarMenu = (props) => {
	const isAuth = useSelector((state) => state.gReducer.isAuth);
	const dispatch = useDispatch();
	const [menuOpen, setMenuOpen] = useState(false);

	const handleStateChange = (state) => {
		setMenuOpen(state.isOpen);
	}


	return (
		// Pass on our props
		<Menu 
			right 
			noOverlay 
			//isOpen={ sidebaropen }
			//{...props}
			isOpen={menuOpen}
			onStateChange={(state) => handleStateChange(state)}
		>

			<a className="menu-item" href="/">
				Home
			</a>


			{
				isAuth==true ? 
				<a className="menu-item" href="/dashboard">
					Profile
				</a>
				: null
			}
			

			<a className="menu-item" href="/contact">
				Contact
			</a>

			<a className="menu-item" href="/featurerequest">
				Feature Request
			</a>

			<a className="menu-item" href="/playground">
				Playground
			</a>

			<a className="menu-item" href="/blog">Blog</a>


			{/* <a rel="noopener noreferrer" className="menu-item" href="https://ducopy.com/blog/">Blog</a> */}

			

			<button
				onClick={() => {
					if (isAuth == true) {
						setTimeout(()=>{
							dispatch(showlogin(false));
							localStorage.clear();
							window.location = "/";
							setMenuOpen(false);
						},700);
						helper.showSuccess("Successfully logged out!");
					} else {
						dispatch(showlogin(true));
						setMenuOpen(false);
					}
				}}
				className="btn-default"
				type="button"
				style={{
					letterSpacing: "0px",
					fontWeight: "bold",
					width: "100%",
					textTransform: "none",
					borderRadius: 4,
					padding: `5px 2px`,
				}}
			>
				{isAuth == true ? (
					<div>
						<AiOutlineLogout /> Logout
					</div>
				) : null}

				{isAuth == false ? (
					<div>
						<AiOutlineLogin /> Login
					</div>
				) : null}
			</button>
						
		</Menu>
	);
};

export default MySidebarMenu;
