import {
  SET_AUTH,
  SET_RULES,
  SHOW_LOGIN,
  SHOW_REGISTER,
  SHOW_FORGOT_PASSWORD
} from "../actions/actionTypes";
import helper from "../Helper";

const initialState = {
    isAuth: localStorage.getItem("authkey")!=null ? true : false,
    allrules: helper.IsJsonString(localStorage.getItem("allrules"))==true ? JSON.parse(localStorage.getItem("allrules")) : [],
    showlogin:false,
    showregister:false,
    showforgotpassword:false
};

const gReducer = (state = initialState, action) => {
    switch (action.type) {
        case SET_AUTH:
            return {
                ...state,
                isAuth: action.data,
            };
        case SET_RULES:
            return {
                ...state,
                allrules: action.data,
            };
        case SHOW_LOGIN:
            return {
                ...state,
                showlogin: action.data,
            };
        case SHOW_REGISTER:
            return {
                ...state,
                showregister: action.data,
            };
        case SHOW_FORGOT_PASSWORD:
            return {
                ...state,
                showforgotpassword: action.data,
            };
        default:
            return state;
    }
};

export default gReducer;
